import { useCallback } from "react";

import ICollectionEntity from "@app/features/Collection/data/ICollectionEntity";

import { TCreateCollectionRemoteData } from "@app/services/collection/remote-types/TCreateCollectionRemoteData";
import { TEditCollectionRemoteData } from "@app/services/collection/remote-types/TEditCollectionRemoteData";

import {
  ICollectionService,
  TListCollectionsSearchParams,
} from "@app/services/collection";
import IPaginationModel, { IPageModel } from "@app/constants/interfaces";
import ILessonEntity from "@app/features/Lesson/data/ILessonEntity";

export interface ICollectionRepository {
  createCollection(data: TCreateCollectionRemoteData): Promise<void>;
  editCollection(data: TEditCollectionRemoteData): Promise<void>;
  deleteCollection(collectionId: number): Promise<void>;
  getCollection(collectionId: number): Promise<ICollectionEntity>;
  listCollections(
    companyId: number,
    pageConfig?: IPageModel,
    filterParams?: TListCollectionsSearchParams
  ): Promise<ICollectionEntity[]>;
  listLessonsFromCollection(
    collectionId: number,
    pageConfig?: IPageModel,
    filterParams?: TListCollectionsSearchParams
  ): Promise<IPaginationModel<ILessonEntity>>;
}

const useCollectionRepository = (
  collectionService: ICollectionService
): ICollectionRepository => {
  const createCollection = useCallback(
    async (data: TCreateCollectionRemoteData): Promise<void> => {
      await collectionService.createCollectionService(data);
    },
    [collectionService]
  );

  const editCollection = useCallback(
    async (data: TEditCollectionRemoteData): Promise<void> => {
      await collectionService.editCollectionService(data);
    },
    [collectionService]
  );

  const listCollections = useCallback(
    async (
      companyId: number,
      pageConfig?: IPageModel,
      filterParams?: TListCollectionsSearchParams
    ): Promise<ICollectionEntity[]> => {
      const collections = await collectionService.listCollectionsService(
        companyId,
        pageConfig,
        filterParams
      );
      return collections;
    },
    [collectionService]
  );

  const getCollection = useCallback(
    async (tagId: number): Promise<ICollectionEntity> => {
      const collection = await collectionService.getCollectionService(tagId);
      return collection;
    },
    [collectionService]
  );

  const deleteCollection = useCallback(
    async (collectionId: number): Promise<void> => {
      await collectionService.deleteCollectionService(collectionId);
    },
    [collectionService]
  );

  const listLessonsFromCollection = useCallback(
    async (
      collectionId: number,
      pageConfig?: IPageModel,
      filterParams?: TListCollectionsSearchParams
    ): Promise<IPaginationModel<ILessonEntity>> => {
      const data = await collectionService.listLessonsFromCollectionService(
        collectionId,
        pageConfig,
        filterParams
      );

      return data;
    },
    [collectionService]
  );

  return {
    createCollection,
    editCollection,
    listCollections,
    deleteCollection,
    getCollection,
    listLessonsFromCollection,
  };
};

export { useCollectionRepository };
