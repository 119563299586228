import { useCallback } from "react";

import { ILanguageRepository } from "@app/features/Language/data/languageRepository";

import getAvailableLanguagesUseCase from "@app/features/Language/domain/use-cases/getAvailableLanguagesUseCase";

import handleApplicationError from "@app/handlers/handleApiError";

const useListLanguagesViewModel = (languageRepository: ILanguageRepository) => {
  const getAvailableLanguages = useCallback(async () => {
    try {
      await getAvailableLanguagesUseCase({
        getAvailableLanguages: languageRepository.getAvailableLanguages,
      });
    } catch (error) {
      handleApplicationError.handleError(error);
    }
  }, [languageRepository.getAvailableLanguages]);

  return {
    getAvailableLanguages,
  };
};

export { useListLanguagesViewModel };
