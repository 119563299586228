import React, { ReactNode, useCallback, useState } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { FiFile, FiYoutube } from "react-icons/fi";

import theme from "@app/config/theme";

export enum OptionType {
  FILE = "FILE",
  EMBED = "EMBED",
}

type ResourceTypeProps = {
  selected?: OptionType;
  onChange: (value: OptionType) => void;
  maxItems?: number;
};

type ResourceTypeItemProps = {
  label: string;
  description: string;
  onSelect: () => void;
  selected?: boolean;
  icon: ReactNode;
};

const ResourceTypeItem = ({
  label,
  description,
  selected,
  icon,
  onSelect,
}: ResourceTypeItemProps) => {
  return (
    <Box
      onClick={onSelect}
      sx={{
        borderWidth: 2,
        borderColor: selected
          ? theme.palette.primary.light
          : theme.palette.grey[400],
        borderStyle: "solid",
        borderRadius: 2,
        p: 1,
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        {icon}
        <Box sx={{ ml: 1 }}>
          <Typography
            sx={{ fontSize: 22 }}
            color={
              selected
                ? theme.palette.primary.light
                : theme.palette.text.secondary
            }
          >
            {label}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ResourceType = ({ onChange, maxItems, selected }: ResourceTypeProps) => {
  const [type, setType] = useState<OptionType | undefined>(selected);

  const handleChange = useCallback(
    (value: OptionType) => {
      setType(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      justifyContent="center"
      alignItems="center"
      flexDirection={"row"}
      spacing={1}
    >
      <Grid item xs={12} sm={12}>
        <Typography color="text.secondary" variant="h6">
          {`Escolha o tipo recurso que deseja adicionar:`}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ResourceTypeItem
          label="Arquivo"
          description={`Faça upload de ${
            maxItems ? maxItems : ""
          } arquivo(s) para sua biblioteca, os arquivos ficarão de disponíveis para uso em turmas que possuem o mesmo idioma que selecionado`}
          onSelect={() => handleChange(OptionType.FILE)}
          selected={type === "FILE"}
          icon={
            <FiFile
              size={35}
              color={
                type === "FILE"
                  ? theme.palette.primary.light
                  : theme.palette.text.secondary
              }
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ResourceTypeItem
          label="Video do youtube"
          description={`Adicione ${
            maxItems ? maxItems : ""
          } video(s) do youtube para sua biblioteca, os video(s) ficarão de disponíveis para uso em turmas que possuem o mesmo idioma que selecionado`}
          onSelect={() => handleChange(OptionType.EMBED)}
          selected={type === "EMBED"}
          icon={
            <FiYoutube
              size={35}
              color={
                type === "EMBED"
                  ? theme.palette.primary.light
                  : theme.palette.text.secondary
              }
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default ResourceType;
