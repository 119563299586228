import { IModuleRepository } from "@app/features/Module/data/moduleRepository";
import { TAddLessonToModuleViewModel } from "@app/features/Module/view/List/listModuleViewModel";

const createModuleUseCase = async (
  repository: Pick<IModuleRepository, "addLessonToModule">,
  data: TAddLessonToModuleViewModel
) => {
  await repository.addLessonToModule(data);
};

export default createModuleUseCase;
