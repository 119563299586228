import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FiCast, FiFileText, FiPlus, FiTrash } from "react-icons/fi";

import {
  Paper,
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
  FormHelperText,
  Stack,
  InputAdornment,
  FormLabel,
  Button,
  Divider,
  Box,
  MenuItem,
  IconButton,
} from "@mui/material";

import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";
import { useFormLessonViewModel } from "@app/features/Lesson/view/Form/formLessonViewModel";

import UploadAsset from "@app/components/molecules/UploadAsset";
import { TUploadPayload } from "@app/components/atoms/Upload";
import TextEditor from "@app/components/organisms/TextEditor";
import DrawerResource, {
  DrawerResourceRef,
} from "@app/components/organisms/DrawerResource";

import { RESOURCE_TYPE } from "@app/constants/enums";
import theme from "@app/config/theme";
import Util from "@app/util";
import { OptionType } from "@app/components/organisms/ResourceType";
import SelectUseForm from "@app/components/organisms/SelectUseForm";
import resourceService from "@app/services/resource";
import tagService from "@app/services/tag";
import { useTagRepository } from "@app/features/Tag/data/tagRepository";
import { useResourceRepository } from "@app/features/Resource/data/resourceRepository";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/store";
import Drawer, { IDrawerRef } from "@app/components/molecules/Drawer";
import CreateTag from "@app/features/Tag/views/Create";

export type TLessonForm = {
  image?: string;
  name: string;
  description?: string;
  resourceMedia?: IResourceModel;
  complementaryMaterials: IResourceModel[];
  minScore: boolean;
  minScoreValue: number;
  canRetake: boolean;
  showResult: boolean;
  randomQuestions: boolean;
  randomAnswers: boolean;
  tagId?: number;
};

type LessonFormProps = {
  onValidateSuccess: (data: TLessonForm) => void;
  footerActions: ReactNode;
  dataForm?: TLessonForm;
  edit?: boolean;
};

const LessonForm = ({
  onValidateSuccess,
  footerActions,
  dataForm,
  edit,
}: LessonFormProps) => {
  const drawerMediaResourceRef = useRef<DrawerResourceRef>();
  const drawerComplementaryMaterialRef = useRef<DrawerResourceRef>();
  const drawerTagRef = useRef<IDrawerRef>();

  const [thumbnail, setThumbnail] = useState(dataForm?.image);
  const { currentCompany } = useSelector((state: RootState) => state.company);
  const { currentLanguage } = useSelector((state: RootState) => state.language);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
    control,
  } = useForm<TLessonForm>({
    resolver: yupResolver(
      yup.object().shape({
        image: yup.string(),
        name: yup.string().required("Preencha o campo nome"),
        description: yup.string(),
        resourceMedia: yup.object().optional(),
        tagId: yup.number().optional(),
        complementaryMaterials: yup.array().optional().max(3),
        minScore: yup.boolean(),
        minScoreValue: yup
          .number()
          .optional()
          .when("minScore", {
            is: true,
            then: yup
              .number()
              .required("Coloque uma porcetagem de acerto para nota mínima.")
              .max(100),
            otherwise: yup.number().nullable(),
          }),
        canRetake: yup.boolean(),
        showResult: yup.boolean(),
        randomQuestions: yup.boolean(),
        randomAnswers: yup.boolean(),
      })
    ),
    defaultValues: {
      image: "",
      name: "",
      description: "",
      resourceMedia: undefined,
      complementaryMaterials: [] as IResourceModel[],
      minScore: false,
      minScoreValue: 0,
      canRetake: true,
      showResult: false,
      randomQuestions: false,
      randomAnswers: false,
      tagId: undefined,
    },
    mode: "onSubmit",
  });

  const resourceRepository = useResourceRepository(resourceService);
  const tagRepository = useTagRepository(tagService);
  const { listTags, tags } = useFormLessonViewModel(
    resourceRepository,
    tagRepository
  );

  const handleUploadSuccess = useCallback(
    (data: TUploadPayload) => {
      setValue("image", data.fileKey);
      setThumbnail(data.url);
    },
    [setValue]
  );

  const handleRemoveUpload = useCallback(() => {
    setValue("image", "");
    setThumbnail(undefined);
  }, [setValue]);

  const onSubmit = useCallback(
    (data: TLessonForm) => {
      onValidateSuccess({
        ...data,
      });
    },
    [onValidateSuccess]
  );

  const [
    description,
    minScore,
    canRetake,
    showResult,
    randomQuestions,
    randomAnswers,
    complementaryMaterials,
    resourceMedia,
  ] = watch([
    "description",
    "minScore",
    "canRetake",
    "showResult",
    "randomQuestions",
    "randomAnswers",
    "complementaryMaterials",
    "resourceMedia",
  ]);

  const handleGetTYoutubeId = () => {
    const { resourceMedia } = getValues();

    return Util.getYoutubeId(resourceMedia?.url);
  };

  const handleOpenModalResourceMedia = useCallback(() => {
    drawerMediaResourceRef.current?.openDrawer();
  }, []);

  const handleOpenModalResourceDocument = useCallback(() => {
    drawerComplementaryMaterialRef.current?.openDrawer();
  }, []);

  const handleRemoveComplementaryMaterial = useCallback(
    (index: number) => {
      complementaryMaterials?.splice(index, 1);
      setValue("complementaryMaterials", complementaryMaterials);
    },
    [complementaryMaterials, setValue]
  );

  const handleSelectResourceMedia = useCallback(
    (data: IResourceModel[]) => {
      drawerMediaResourceRef.current?.closeDrawer();
      if (data.length) {
        const item = data[0];
        setValue("resourceMedia", item);
      }
    },
    [setValue]
  );

  const handleChangeDescription = useCallback(
    (value: string) => {
      setValue("description", value);
    },
    [setValue]
  );

  const handleRemoveMedia = useCallback(() => {
    setValue("resourceMedia", undefined);
  }, [setValue]);

  const handleSelectResource = useCallback(
    (data: IResourceModel[]) => {
      setValue("complementaryMaterials", [...complementaryMaterials, ...data]);
      drawerComplementaryMaterialRef.current?.closeDrawer();
    },
    [setValue, complementaryMaterials]
  );

  const handleOpenTagDrawer = useCallback(() => {
    drawerTagRef.current?.openDrawer();
  }, []);

  const handleCloseDrawerTag = useCallback(() => {
    drawerTagRef.current?.closeDrawer();
    if (currentCompany && currentLanguage) {
      listTags(currentCompany.id, currentLanguage.id);
    }
  }, [listTags, currentCompany, currentLanguage]);

  useEffect(() => {
    setValue("minScoreValue", minScore ? 60 : 0);
  }, [minScore, setValue]);

  useEffect(() => {
    reset(dataForm);

    setThumbnail(dataForm?.image);
  }, [dataForm, reset, setValue]);

  useEffect(() => {
    if (currentCompany && currentLanguage) {
      listTags(currentCompany.id, currentLanguage.id);
    }
  }, [currentCompany, listTags, currentLanguage]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid
            container
            xs={12}
            sm={12}
            sx={{ marginTop: 5 }}
            justifyContent="center"
            alignItems="center"
          >
            <UploadAsset
              accept={{ image: true }}
              destination="LESSON"
              label="Escolha uma capa para a aula"
              url={thumbnail}
              error={!!errors.image}
              errorMessage={errors.image?.message}
              onRemove={handleRemoveUpload}
              onUploadSuccess={handleUploadSuccess}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="name"
              type="text"
              fullWidth
              margin="normal"
              variant="outlined"
              label="Nome"
              InputLabelProps={{ shrink: true }}
              error={!!errors.name}
              helperText={!!errors.name && errors.name.message}
              {...register("name")}
            />
          </Grid>
          <Grid item xs={10} sm={5}>
            <SelectUseForm
              error={!!errors.tagId}
              helperText={errors.tagId?.message}
              control={control}
              label="Marcador"
              defaultValue={String(getValues("tagId"))}
              {...register("tagId")}
            >
              {tags.map((option) => (
                <MenuItem value={option.id} key={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </SelectUseForm>
          </Grid>
          <Grid item xs={2} sm={1} sx={{ mt: 2 }}>
            <IconButton
              aria-label="delete"
              size="large"
              color="primary"
              disabled={false}
              onClick={handleOpenTagDrawer}
            >
              <FiPlus />
            </IconButton>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextEditor
              value={description}
              onChange={handleChangeDescription}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormLabel component="legend" sx={{ mb: 1 }}>
              Recurso da aula (Apenas 1 item)
            </FormLabel>
            {resourceMedia && (
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  flexDirection: "column",
                  display: "flex",
                }}
                alignItems={"flex-start"}
                flexDirection="column"
              >
                {resourceMedia.type === RESOURCE_TYPE.EMBED && (
                  <iframe
                    width="350"
                    height="155"
                    src={`https://www.youtube.com/embed/${handleGetTYoutubeId()}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    style={{ borderRadius: 5, borderWidth: 0 }}
                  />
                )}
                {resourceMedia.type === RESOURCE_TYPE.AUDIO && (
                  <Box>
                    <audio controls>
                      <source src={resourceMedia.url} />
                      Your browser does not support the audio element.
                    </audio>
                  </Box>
                )}
                {resourceMedia.type === RESOURCE_TYPE.IMAGE && (
                  <Box>
                    <img
                      alt={resourceMedia.name}
                      src={resourceMedia.url}
                      style={{
                        borderRadius: 5,
                        borderWidth: 0,
                        height: 155,
                      }}
                    />
                  </Box>
                )}
                <Button
                  variant="text"
                  onClick={handleRemoveMedia}
                  endIcon={<FiTrash color={theme.palette.error.main} />}
                >
                  Remover mídia
                </Button>
              </Grid>
            )}
            {!resourceMedia && (
              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleOpenModalResourceMedia}
                  startIcon={<FiCast />}
                >
                  Adicionar mídia
                </Button>
              </Grid>
            )}
          </Grid>
          <Divider />
          <Grid item xs={12} sm={12}>
            <FormLabel component="legend" sx={{ mb: 1 }}>
              Materiais complementares (Máximo de 3 items)
            </FormLabel>
            {!!complementaryMaterials?.length &&
              complementaryMaterials?.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                  alignItems={"flex-start"}
                  flexDirection="column"
                >
                  <Button
                    variant="text"
                    onClick={() => handleRemoveComplementaryMaterial(index)}
                    startIcon={<FiFileText />}
                    endIcon={<FiTrash color={theme.palette.error.main} />}
                  >
                    {item.name}
                  </Button>
                </Grid>
              ))}
            {!!complementaryMaterials && complementaryMaterials.length < 3 && (
              <Button
                variant="contained"
                size="small"
                onClick={handleOpenModalResourceDocument}
                startIcon={<FiFileText />}
              >
                Adicionar material complementar
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={12}>
            <FormLabel component="legend">Configurações da aula</FormLabel>
            <FormGroup>
              <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={getValues("minScore")}
                      {...register("minScore")}
                    />
                  }
                  label="Exigir nota mínima?"
                />
                {minScore && (
                  <TextField
                    id="standard-basic"
                    label="Percentual de acerto"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                    type="number"
                    error={!!errors.minScoreValue}
                    helperText={
                      !!errors.minScoreValue && errors.minScoreValue.message
                    }
                    {...register("minScoreValue")}
                    placeholder="Ex: 60%"
                  />
                )}
              </Stack>
              <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                <FormControlLabel
                  control={
                    <Switch {...register("canRetake")} checked={canRetake} />
                  }
                  label="Essa aula pode ser refeita?"
                />
              </Stack>
              <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                <FormControlLabel
                  control={
                    <Switch {...register("showResult")} checked={showResult} />
                  }
                  label="Exibir respostas após resultado?"
                />
              </Stack>
              <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      {...register("randomQuestions")}
                      checked={randomQuestions}
                    />
                  }
                  label="Colocar perguntas em ordem aleátoria?"
                />
              </Stack>
              <Stack direction="row" alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      {...register("randomAnswers")}
                      checked={randomAnswers}
                    />
                  }
                  label="Colocar respostas em ordem aleátoria? "
                />
              </Stack>
              <FormHelperText>
                Válido apenas para perguntas do tipo multipla escolha
              </FormHelperText>
            </FormGroup>
          </Grid>
        </Grid>
      </Paper>
      <DrawerResource
        ref={drawerMediaResourceRef}
        title="Mídia"
        onAddItems={handleSelectResourceMedia}
        maxItems={1}
        acceptedFiles={{ audio: true, image: true }}
        resourcesAllowed={[
          RESOURCE_TYPE.AUDIO,
          RESOURCE_TYPE.EMBED,
          RESOURCE_TYPE.IMAGE,
        ]}
      />
      <DrawerResource
        ref={drawerComplementaryMaterialRef}
        title="Materiais"
        onAddItems={handleSelectResource}
        uploadAllowedTypes={OptionType.FILE}
        acceptedFiles={{ file: true }}
        maxItems={3}
        resourcesAllowed={[RESOURCE_TYPE.DOC]}
      />

      <Drawer ref={drawerTagRef} drawerPosition="bottom">
        <Box sx={{ p: 4 }}>
          <CreateTag onFinish={handleCloseDrawerTag} />
        </Box>
      </Drawer>

      {footerActions}
    </form>
  );
};

export default LessonForm;
