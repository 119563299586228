import React, { ReactNode, useCallback, useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Paper, TextField, Grid, MenuItem } from "@mui/material";
import { Box } from "@mui/system";

import SelectUseForm from "@app/components/organisms/SelectUseForm";

import Util from "@app/util";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/store";

export type TTagForm = {
  name: string;
  color: string;
  languageId: number;
};

type TagProps = {
  onValidateSuccess: (data: TTagForm) => void;
  footerActions: ReactNode;
  dataForm?: TTagForm;
  edit?: boolean;
};

const TagForm = ({
  onValidateSuccess,
  footerActions,
  dataForm,
  edit,
}: TagProps) => {
  const { languages, currentLanguage } = useSelector(
    (state: RootState) => state.language
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    control,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Preencha o campo nome"),
        color: yup.string().required("Selecione uma cor"),
        languageId: yup
          .number()
          .typeError("Seleciona um idioma")
          .required("Seleciona um idioma"),
      })
    ),
    defaultValues: {
      name: "",
      color: "",
      languageId: currentLanguage?.id ?? NaN,
    },
    mode: "onSubmit",
  });

  const onSubmit = useCallback(
    (data: TTagForm) => {
      onValidateSuccess(data);
    },
    [onValidateSuccess]
  );

  const generateColor = useCallback(() => {
    const newColor = Util.generateColor();
    setValue("color", `#${newColor}`);
  }, [setValue]);

  useEffect(() => {
    reset(dataForm);
    if (!dataForm?.color) {
      generateColor();
    }
  }, [dataForm, reset, generateColor]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <TextField
              id="name"
              type="text"
              fullWidth
              margin="normal"
              variant="outlined"
              label="Nome"
              InputLabelProps={{ shrink: true }}
              error={!!errors.name}
              helperText={!!errors.name && errors.name.message}
              {...register("name")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="brandColor"
              type={"color"}
              fullWidth
              margin="normal"
              variant="outlined"
              label="Cor do marcador"
              {...register("color")}
              error={!!errors.color}
              helperText={!!errors.color && errors.color.message}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectUseForm
              error={!!errors.languageId}
              helperText={errors.languageId?.message}
              control={control}
              label="Idioma do marcador"
              defaultValue={String(getValues("languageId"))}
              {...register("languageId")}
            >
              {languages.map((language) => (
                <MenuItem value={language.id} key={language.key}>
                  {language.name}
                </MenuItem>
              ))}
            </SelectUseForm>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ mt: 10 }}>{footerActions}</Box>
    </form>
  );
};

export default TagForm;
