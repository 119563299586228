import ILanguageModel from "@app/features/Language/domain/models/ILanguageModel";

export const LOADING_LANGUAGE = "LOADING_LANGUAGE";
export const LANGUAGES = "LANGUAGES";
export const SET_CURRENT_LANGUAGE = "SET_CURRENT_LANGUAGE";

export interface ILanguageStore {
  loading: boolean;
  languages: ILanguageModel[];
  currentLanguage: ILanguageModel | undefined;
}

interface LoadingLanguageAction {
  type: typeof LOADING_LANGUAGE;
  payload: boolean;
}

interface SetLanguagesAction {
  type: typeof LANGUAGES;
  payload: ILanguageModel[];
}

interface SetCurrentLanguageAction {
  type: typeof SET_CURRENT_LANGUAGE;
  payload: ILanguageModel;
}

export type LanguageActionTypes =
  | LoadingLanguageAction
  | SetLanguagesAction
  | SetCurrentLanguageAction;
