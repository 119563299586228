import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { FiFile, FiImage, FiMusic, FiYoutube, FiUpload } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Chip, Stack } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import { useSelector } from "react-redux";

import { useResourceRepository } from "@app/features/Resource/data/resourceRepository";
import { useTagRepository } from "@app/features/Tag/data/tagRepository";

import resourceService, {
  TListResourcesSearchParams,
} from "@app/services/resource";
import tagService from "@app/services/tag";

import { useListResourcesViewModel } from "@app/features/Resource/views/List/listResourcesViewModel";
import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";

import LoadingLock from "@app/components/molecules/LoadingLock";
import StickyHeaderTable from "@app/components/organisms/StickyHeaderTable";
import StandardTemplate from "@app/components/templates/Standard";
import DrawerFilter, {
  IDrawerFilterRef,
  TFilterPayload,
} from "@app/components/organisms/DrawerFilter";

import { RootState } from "@app/config/store";
import { IPageModel } from "@app/constants/interfaces";
import {
  RESOURCE_TYPES_OPTIONS,
  ROWS_PER_PAGE_OPTIONS,
} from "@app/constants/optionsSelect";
import Util from "@app/util";
import { RESOURCE_TYPE } from "@app/constants/enums";
import theme from "@app/config/theme";

const ListResources = () => {
  const navigate = useNavigate();
  const draweFilterRef = useRef<IDrawerFilterRef>();

  const resourceRespository = useResourceRepository(resourceService);
  const tagRepository = useTagRepository(tagService);

  const {
    deleteResource,
    listResources,
    isLoading,
    resources,
    tags,
    listTags,
  } = useListResourcesViewModel(resourceRespository, tagRepository);

  const { currentCompany } = useSelector((state: RootState) => state.company);
  const { languages } = useSelector((state: RootState) => state.language);

  const [filters, setFilters] = useState<TFilterPayload[]>([]);
  const [paginationConfig, setPaginationConfig] = useState<IPageModel>({
    page: 0,
    amount: ROWS_PER_PAGE_OPTIONS.MIN,
  });

  const handleClickPrimaryButton = useCallback(() => {
    navigate("/admin/resources/create");
  }, [navigate]);

  const handleEditResource = useCallback(
    (data: IResourceModel) => {
      navigate(`/admin/resources/edit/${data.id}`);
    },
    [navigate]
  );

  const handleSetFilter = useCallback((values: TFilterPayload[]) => {
    setFilters([...values]);
  }, []);

  const removeFilter = useCallback(
    (filter: TFilterPayload) => {
      let newFilters = filters.filter((f) => f.id !== filter.id);
      setFilters([...newFilters]);
    },
    [filters]
  );

  const handleGetNewPage = useCallback((newPage: number, amount: number) => {
    setPaginationConfig({ amount, page: newPage });
  }, []);

  const getDocIcon = useCallback((data: IResourceModel) => {
    switch (data.type) {
      case RESOURCE_TYPE.AUDIO:
        return <FiMusic color={theme.palette.primary.main} size={20} />;
      case RESOURCE_TYPE.DOC:
        return <FiFile color={theme.palette.primary.main} size={20} />;
      case RESOURCE_TYPE.EMBED:
        return <FiYoutube color={theme.palette.primary.main} size={20} />;
      case RESOURCE_TYPE.IMAGE:
        return <FiImage color={theme.palette.primary.main} size={20} />;
    }
  }, []);

  const handleDeleteResource = useCallback(
    (data: IResourceModel) => {
      if (currentCompany) {
        deleteResource(data.id, currentCompany.id, paginationConfig);
      }
    },
    [deleteResource, currentCompany, paginationConfig]
  );

  const languagesData = useMemo(() => {
    return languages.map((item): { label: string; value: string } => {
      return {
        label: item.name,
        value: item.id.toString(),
      };
    });
  }, [languages]);

  const tagsData = useMemo(() => {
    return tags.map((item): { label: string; value: string } => {
      return {
        label: item.name,
        value: item.id.toString(),
      };
    });
  }, [tags]);

  useEffect(() => {
    if (currentCompany) {
      listTags(currentCompany.id);
    }
  }, [currentCompany, listTags]);

  useEffect(() => {
    let filtersData: TListResourcesSearchParams = {};

    filters.forEach((f) => {
      if (f.value) {
        filtersData = { ...filtersData, [f.id]: f.value };
      }
    });

    if (currentCompany) {
      listResources(currentCompany.id, paginationConfig, filtersData);
    }
  }, [listResources, currentCompany, filters, paginationConfig]);

  return (
    <>
      <LoadingLock isLoading={isLoading} />

      <StandardTemplate
        title="Biblioteca"
        primaryButton={{
          textButton: "Upload de recurso",
          isVisible: true,
          iconButton: <FiUpload />,
          onClickButton: handleClickPrimaryButton,
        }}
        secondaryButton={{
          textButton: "Filtro",
          isVisible: true,
          iconButton: <FilterAlt />,
          onClickButton: draweFilterRef.current?.openDrawer,
        }}
      >
        {!!filters.length && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ mb: 3 }}
          >
            {filters.map((filter) => (
              <Chip
                variant="outlined"
                label={`${filter.labelFilter}: ${filter.selectValueLabel}`}
                onDelete={() => removeFilter(filter)}
              />
            ))}
          </Stack>
        )}
        <StickyHeaderTable
          columns={[
            {
              id: "type",
              label: "",
              component: "CUSTOM",
              align: "left",
              customComponent: (data) => getDocIcon(data as IResourceModel),
            },
            {
              id: "name",
              label: "Nome",
              align: "left",
            },
            {
              id: "size",
              label: "Tamanho",
              align: "left",
              format: (value) => {
                return Util.formatBytes(value);
              },
            },
            {
              id: "language.name",
              label: "Idioma",
              align: "center",
              component: "CUSTOM",
              customComponent: (data) => (
                <Chip label={data.language.name} color={"primary"} />
              ),
            },
            {
              id: "tag.name",
              label: "Marcador",
              align: "center",
              component: "CUSTOM",
              customComponent: (data) => {
                if (data.tag) {
                  return (
                    <Chip
                      label={data?.tag?.name}
                      sx={{ backgroundColor: data?.tag?.color }}
                    />
                  );
                }
              },
            },
            {
              id: "type",
              label: "Tipo do recurso",
              align: "left",
            },
          ]}
          dialogMessage={{
            title: "Remover recurso?",
            description:
              "Tem certeza que deseja remover este recurso? Esse recurso será removido de todas as aulas e perguntas vinculados a ele.",
          }}
          pagination
          count={resources.count}
          identifierColumnKey="id"
          onPageChange={handleGetNewPage}
          onDelete={handleDeleteResource}
          onEdit={handleEditResource}
          rows={resources.data}
        />
      </StandardTemplate>
      <DrawerFilter
        ref={draweFilterRef}
        onFilter={handleSetFilter}
        fields={[
          {
            id: "name",
            label: "Nome",
            type: "TEXT",
          },
          {
            id: "type",
            label: "Tipo de recurso",
            type: "SELECT",
            options: RESOURCE_TYPES_OPTIONS,
          },
          {
            id: "languageId",
            label: "Idioma do recurso",
            type: "SELECT",
            options: languagesData,
          },
          {
            id: "tagId",
            label: "Marcador",
            type: "SELECT",
            options: tagsData,
          },
        ]}
      />
    </>
  );
};

export default ListResources;
