import { ICollectionRepository } from "@app/features/Collection/data/collectionRepository";

import ILessonEntity from "@app/features/Lesson/data/ILessonEntity";

import ILessonModel from "@app/features/Lesson/domain/models/ILessonModel";
import IPaginationModel, { IPageModel } from "@app/constants/interfaces";

import { TListTagsSearchParams } from "@app/services/tag";

const listCollectionsUseCase = async (
  repository: Pick<ICollectionRepository, "listLessonsFromCollection">,
  companyId: number,
  pageConfig?: IPageModel,
  filterParams?: TListTagsSearchParams
): Promise<IPaginationModel<ILessonModel>> => {
  const lessonsEntity = await repository.listLessonsFromCollection(
    companyId,
    pageConfig,
    filterParams
  );

  const domain = mapToDomain(lessonsEntity.data);

  return {
    count: lessonsEntity.count,
    data: domain,
  };
};

const mapToDomain = (lessonsEntity: ILessonEntity[]): ILessonModel[] => {
  const domain = lessonsEntity.map((lesson): ILessonModel => {
    const {
      id,
      name,
      image,
      canRetake,
      complementaryMaterials,
      minScore,
      minScoreValue,
      randomAnswers,
      randomQuestions,
      showResult,
      tag,
    } = lesson;

    return {
      id,
      name,
      image,
      canRetake,
      complementaryMaterials,
      minScore,
      minScoreValue,
      randomAnswers,
      randomQuestions,
      showResult,
      tag,
    };
  });

  return domain;
};

export default listCollectionsUseCase;
