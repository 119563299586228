import api from "@app/config/api";
import API_SERVICES from "@app/services/constants";

import IModulePageEntity from "@app/features/ModulePage/data/IModulePageEntity";
import { TEditLessonDetailFromModuleRemoteData } from "@app/services/module-page/remote-types/TEditLessonDetailFromModuleRemoteData";

export interface IModulePageService {
  editLessonDetailFromModuleService: (
    data: TEditLessonDetailFromModuleRemoteData
  ) => Promise<void>;
  getLessonDetailFromModuleService: (
    moduleId: number,
    lessonId: number
  ) => Promise<IModulePageEntity>;
  deleteLessonFromModuleService: (
    moduleId: number,
    lessonId: number
  ) => Promise<void>;
}

const editLessonDetailFromModuleService = async (
  data: TEditLessonDetailFromModuleRemoteData
): Promise<void> =>
  api
    .put(
      API_SERVICES.MODULE_PAGE_SERVICES.EDIT_LESSON_DETAIL(data.moduleId),
      data
    )
    .then((res) => res.data);

const getLessonDetailFromModuleService = async (
  moduleId: number,
  lessonId: number
): Promise<IModulePageEntity> =>
  api
    .get(
      API_SERVICES.MODULE_PAGE_SERVICES.GET_LESSON_DETAIL(moduleId, lessonId)
    )
    .then((res) => res.data);

const deleteLessonFromModuleService = async (
  moduleId: number,
  lessonId: number
): Promise<void> =>
  api
    .delete(API_SERVICES.MODULE_PAGE_SERVICES.DELETE(moduleId, lessonId))
    .then((res) => res.data);

const modulePageService: IModulePageService = {
  editLessonDetailFromModuleService,
  deleteLessonFromModuleService,
  getLessonDetailFromModuleService,
};

export default modulePageService;
