import { ITagRepository } from "@app/features/Tag/data/tagRepository";

const deleteModuleUseCase = async (
  repository: Pick<ITagRepository, "deleteTag">,
  tagId: number
) => {
  await repository.deleteTag(tagId);
};

export default deleteModuleUseCase;
