import { Action, Dispatch } from "redux";

import ILanguageModel from "@app/features/Language/domain/models/ILanguageModel";

import {
  LOADING_LANGUAGE,
  LANGUAGES,
  SET_CURRENT_LANGUAGE,
} from "@app/features/Language/data/languageActionsType";

export const setLanguagesAction =
  (languages: ILanguageModel[]) => (dispatch: Dispatch<Action>) => {
    dispatch({ type: LANGUAGES, payload: languages });
  };

export const setLoadingLanguagesAction =
  (value: boolean) =>
  (dispatch: Dispatch<Action>): Action =>
    dispatch({ type: LOADING_LANGUAGE, payload: value });

export const setCurrentLanguageAction =
  (value: ILanguageModel) =>
  (dispatch: Dispatch<Action>): Action =>
    dispatch({ type: SET_CURRENT_LANGUAGE, payload: value });
