import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { useCollectionRepository } from "@app/features/Collection/data/collectionRepository";
import { useCreateCollectionViewModel } from "@app/features/Collection/views/Create/createCollectionViewModel";
import collectionService from "@app/services/collection";

import StandardTemplate from "@app/components/templates/Standard";
import LoadingLock from "@app/components/molecules/LoadingLock";
import FooterActions from "@app/components/molecules/FooterActions";

import CollectionForm, {
  TCollectionForm,
} from "@app/features/Collection/views/Form";

import { RootState } from "@app/config/store";

const CreateCollection = () => {
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const collectionRepository = useCollectionRepository(collectionService);
  const { createCollection, isLoading } =
    useCreateCollectionViewModel(collectionRepository);

  const handleSave = useCallback(
    (data: TCollectionForm) => {
      if (currentCompany) {
        createCollection({ companyId: currentCompany.id, ...data });
      }
    },
    [currentCompany, createCollection]
  );

  return (
    <StandardTemplate title="Nova coleção" showBackButton>
      <LoadingLock isLoading={isLoading} />
      <CollectionForm
        onValidateSuccess={handleSave}
        footerActions={<FooterActions />}
      />
    </StandardTemplate>
  );
};

export default CreateCollection;
