import { ITagRepository } from "@app/features/Tag/data/tagRepository";
import { TCreateTagViewModel } from "@app/features/Tag/views/Create/createTagViewModel";

const createModuleUseCase = async (
  repository: Pick<ITagRepository, "createTag">,
  data: TCreateTagViewModel
) => {
  await repository.createTag(data);
};

export default createModuleUseCase;
