import { useCallback } from "react";

import ILessonEntity from "@app/features/Lesson/data/ILessonEntity";
import IAnswerResultEntity from "@app/features/Lesson/data/IAnswerResultEntity";

import { TCreateLessonRemoteData } from "@app/services/lesson/remote-types/TCreateLessonRemoteData";
import { TEditLessonRemoteData } from "@app/services/lesson/remote-types/TEditLessonRemoteData";

import { ILessonService } from "@app/services/lesson";

export interface ILessonRepository {
  createLesson(data: TCreateLessonRemoteData): Promise<void>;
  removeLesson(lessonId: number): Promise<void>;
  getLesson(lessonId: number): Promise<ILessonEntity>;
  editLesson(data: TEditLessonRemoteData): Promise<void>;
  getAnswerFromStudent(studentPageId: number): Promise<IAnswerResultEntity[]>;
}

const useLessonRepository = (
  lessonService: ILessonService
): ILessonRepository => {
  const createLesson = useCallback(
    async (data: TCreateLessonRemoteData): Promise<void> => {
      await lessonService.createLessonService(data);
    },
    [lessonService]
  );

  const removeLesson = useCallback(
    async (lessonId: number): Promise<void> => {
      await lessonService.removeLessonService(lessonId);
    },
    [lessonService]
  );

  const getLesson = useCallback(
    async (lessonId: number): Promise<ILessonEntity> => {
      const lesson = await lessonService.getLessonService(lessonId);
      return lesson;
    },
    [lessonService]
  );

  const editLesson = useCallback(
    async (data: TEditLessonRemoteData): Promise<void> => {
      await lessonService.editLessonService(data);
    },
    [lessonService]
  );

  const getAnswerFromStudent = useCallback(
    async (studentPageId: number): Promise<IAnswerResultEntity[]> => {
      const data = await lessonService.getAnswerFromStudentService(
        studentPageId
      );
      return data;
    },
    [lessonService]
  );

  return {
    createLesson,
    removeLesson,
    getLesson,
    editLesson,
    getAnswerFromStudent,
  };
};

export { useLessonRepository };
