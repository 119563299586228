import { useCallback, useState } from "react";

import listTagsUseCases from "@app/features/Tag/domain/use-cases/listTagsUseCase";
import getYoutubeInfoUseCase from "@app/features/Resource/domain/use-cases/getYoutubeInfoUseCase";

import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";
import { ITagRepository } from "@app/features/Tag/data/tagRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import ITagModel from "@app/features/Tag/domain/models/ITagModel";

const useFormResourceViewModel = (
  tagRepository: ITagRepository,
  resourceRepository: IResourceRepository
) => {
  const [isLoading, setLoading] = useState(false);
  const [tags, setTags] = useState<ITagModel[]>([]);

  const getTags = useCallback(
    async (companyId: number, languageId: number) => {
      try {
        setLoading(true);

        const data = await listTagsUseCases(
          {
            listTags: tagRepository.listTags,
          },
          companyId,
          undefined,
          { languageId }
        );
        setTags(data as ITagModel[]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [tagRepository.listTags]
  );

  const getYoutubeInfo = useCallback(
    async (videourl: string) => {
      try {
        setLoading(true);

        return await getYoutubeInfoUseCase(
          {
            getYoutubeInfo: resourceRepository.getYoutubeInfo,
          },
          videourl
        );
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [resourceRepository.getYoutubeInfo]
  );

  return {
    isLoading,
    tags,
    getYoutubeInfo,
    getTags,
  };
};

export { useFormResourceViewModel };
