import { TAssetPath } from "@app/constants";

const AUTHENTICATION_SERVICES = {
  LOGIN: "/authentication",
  REFRESH_TOKEN: "/authentication/refresh-token",
};

const USER_SERVICES = {
  SIGN_UP: "/users/sign-up",
  RESEND_VERIFICATION_CODE: "/users/resend-email",
  VERIFICATION_CODE: "/users/verification-code",
  FORGOT_PASSWORD: "/users/forgot-password",
  CHANGE_PASSWORD: "/users/change-password",
  GET_USER: (userId: number) => `/users/${userId}`,
  LIST_USER_LESSONS_RESULT: (userId: number, classeId: number) =>
    `/users/${userId}/classe/${classeId}`,
  GET_PROFILE: `/users/me`,
  EDIT_PROFILE: "/users",
  UPDATE_USER_STATUS: "/users/status",
};

const LANGUAGES_SERVICES = {
  LIST: `/languages`,
};

const ROLES_SERVICES = {
  LIST: `/roles`,
};

const DASHBOARD_SERVICES = {
  GET: (classeId: number) => `/dashboard/${classeId}`,
};

const COMPANY_SERVICES = {
  LIST: `/companies`,
  GET: (companyId: number) => `/companies/${companyId}`,
  DELETE: (companyId: number) => `/companies/${companyId}`,
  EDIT: (companyId: number) => `/companies/${companyId}`,
  CREATE: "/companies",
  LIST_ALL_USERS_FROM_COMPANY: (companyId: number) =>
    `/companies/${companyId}/users`,
};

const CLASSE_SERVICES = {
  LIST: (companyId: number) => `/classes/companies/${companyId}`,
  GET_CLASSE: (classeId: number) => `/classes/${classeId}`,
  DELETE_CLASSE: (classeId: number) => `/classes/${classeId}`,
  EDIT_CLASSE: (classeId: number) => `/classes/${classeId}`,
  CREATE: "/classes",
  HANDLE_INVITE: (classeId: number) => `/classes/${classeId}/invite`,
  GET_USERS_FROM_CLASSE: (classeId: number) => `/classes/${classeId}/users`,
  REMOVE_USERS_FROM_CLASSE: (classeId: number, userId: number) =>
    `/classes/${classeId}/users/${userId}`,
  ADD_USER_TO_CLASSE: "/classes/users",
};

const RESOURCE_SERVICES = {
  LIST_RESOURCES: (companyId: number) => `/resources/companies/${companyId}`,
  CREATE_RESOURCES: (companyId: number) => `/resources/${companyId}`,
  DELETE_RESOURCE: (resourceId: number) => `/resources/${resourceId}`,
  GET_RESOURCE: (resourceId: number) => `/resources/${resourceId}`,
  EDIT_RESOURCE: (resourceId: number) => `/resources/${resourceId}`,
  GET_YOUTUBE_INFO: (youtubeUrl: string) =>
    `https://noembed.com/embed?dataType=json&url=${youtubeUrl}`,
};

const MODULE_SERVICES = {
  LIST: "/modules",
  CREATE: "/modules",
  EDIT: (moduleId: number) => `/modules/${moduleId}`,
  DELETE: (moduleId: number) => `/modules/${moduleId}`,
  GET_LESSONS_FROM_MODULE: (moduleId: number) => `/modules/${moduleId}/pages`,
  ADD_LESSONS_TO_MODULE: (moduleId: number) => `/modules/${moduleId}`,
};

const MODULE_PAGE_SERVICES = {
  DELETE: (moduleId: number, lessonId: number) =>
    `/modules-pages/${moduleId}/pages/${lessonId}`,
  GET_LESSON_DETAIL: (moduleId: number, lessonId: number) =>
    `/modules-pages/${moduleId}/pages/${lessonId}`,
  EDIT_LESSON_DETAIL: (moduleId: number) => `/modules-pages/${moduleId}`,
};

const TAG_SERVICES = {
  LIST: (companyId: number) => `/tags/companies/${companyId}`,
  CREATE: "/tags",
  EDIT: (tagId: number) => `/tags/${tagId}`,
  DELETE: (tagId: number) => `/tags/${tagId}`,
  GET: (tagId: number) => `/tags/${tagId}`,
};

const COLLECTION_SERVICES = {
  LIST: (companyId: number) => `/collections/companies/${companyId}`,
  CREATE: "/collections",
  EDIT: (collectionId: number) => `/collections/${collectionId}`,
  DELETE: (collectionId: number) => `/collections/${collectionId}`,
  GET: (collectionId: number) => `/collections/${collectionId}`,
  GET_LESSONS_FROM_COLLECTION: (collectionId: number) =>
    `/collections/${collectionId}/lessons`,
};

const LESSON_SERVICES = {
  LIST: "/lessons",
  CREATE: "/lessons",
  DELETE: (lessonId: number) => `/lessons/${lessonId}`,
  GET: (lessonId: number) => `/lessons/${lessonId}`,
  EDIT: (lessonId: number) => `/lessons/${lessonId}`,
  GET_ANSWERS_FROM_STUDENT: (studentPageId: number) =>
    `/lessons/answers/${studentPageId}`,
};

const QUESTION_SERVICES = {
  CREATE: "questions",
  DELETE: (questionId: number) => `/questions/${questionId}`,
  GENERATE: `/questions/generate`,
  EDIT: (questionId: number) => `/questions/${questionId}`,
};

const UPLOAD_SERVICES = {
  UPLOAD_MULTIPLE: (assetPath: TAssetPath) => `/upload/${assetPath}`,
  DELETE_ASSET: `/upload`,
};

const CNPJ_SERVICES = {
  GET: (value: string) =>
    `https://api-publica.speedio.com.br/buscarcnpj?cnpj=${value}`,
};

const API_SERVICES = {
  AUTHENTICATION_SERVICES,
  USER_SERVICES,
  COMPANY_SERVICES,
  CLASSE_SERVICES,
  LANGUAGES_SERVICES,
  ROLES_SERVICES,
  UPLOAD_SERVICES,
  RESOURCE_SERVICES,
  MODULE_SERVICES,
  LESSON_SERVICES,
  QUESTION_SERVICES,
  CNPJ_SERVICES,
  DASHBOARD_SERVICES,
  TAG_SERVICES,
  COLLECTION_SERVICES,
  MODULE_PAGE_SERVICES,
};

export default API_SERVICES;
