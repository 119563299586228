import { useCallback, useState } from "react";

import { IQuestionRepository } from "@app/features/Question/data/questionRepository";

import removeQuestionUseCase from "@app/features/Question/domain/use-cases/removeQuestionUseCase";
import addQuestionUseCase from "@app/features/Question/domain/use-cases/addQuestionUseCase";
import editQuestionUseCase from "@app/features/Question/domain/use-cases/editQuestionUseCase";
import generateQuestionsUseCase from "@app/features/Question/domain/use-cases/generateQuestionsUseCase";

import showNotification from "@app/components/molecules/Toast";
import { TMultipleChoiceForm } from "@app/features/Question/view/Form/MultipleChoiceForm";
import { TGenericForm } from "@app/features/Question/view/Form/GenericForm";
import { TQuestionForm } from "@app/features/Question/view/Form";

import { AMOUNT_GENERATE_QUESTIONS, QUESTION_TYPE } from "@app/constants/enums";
import handleApplicationError from "@app/handlers/handleApiError";
import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";

export type TEditQuestionViewModel = {
  id: number;
  description: string;
  resourceMedia?: IResourceModel;
  questionType: QUESTION_TYPE;
  data: TMultipleChoiceForm | TGenericForm;
};

export type TAddQuestionViewModel = {
  description: string;
  resourceMedia?: IResourceModel;
  questionType: QUESTION_TYPE;
  data: TMultipleChoiceForm | TGenericForm;
  pageId: number;
};

const useFormQuestionViewModel = (repository: IQuestionRepository) => {
  const [isLoading, setLoading] = useState(false);
  const [generatedQuestions, setGeneratedQuestions] = useState<TQuestionForm>();

  const addQuestion = useCallback(
    async (data: TAddQuestionViewModel) => {
      try {
        setLoading(true);

        const result = await addQuestionUseCase(
          {
            addQuestion: repository.addQuestion,
          },
          data
        );
        setLoading(false);
        showNotification("Pergunta salva com sucesso", "SUCCESS");
        return result;
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.addQuestion]
  );

  const editQuestion = useCallback(
    async (data: TEditQuestionViewModel) => {
      try {
        setLoading(true);

        const result = await editQuestionUseCase(
          {
            editQuestion: repository.editQuestion,
          },
          data
        );
        setLoading(false);
        showNotification("Pergunta alterada com sucesso", "SUCCESS");
        return result;
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.editQuestion]
  );

  const removeQuestion = useCallback(
    async (questionId: number) => {
      try {
        setLoading(true);

        await removeQuestionUseCase(
          {
            removeQuestion: repository.removeQuestion,
          },
          questionId
        );
        setLoading(false);
        showNotification("Pergunta removida com sucesso", "SUCCESS");
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.removeQuestion]
  );

  const generateQuestions = useCallback(
    async (
      subject: string,
      amountQuestions: AMOUNT_GENERATE_QUESTIONS,
      languageId: number
    ) => {
      try {
        setLoading(true);

        const result = await generateQuestionsUseCase(
          {
            generateQuestions: repository.generateQuestions,
          },
          subject,
          amountQuestions,
          languageId
        );
        setGeneratedQuestions(result);
        setLoading(false);
        showNotification("Pergunta geradas com sucesso", "SUCCESS");
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.generateQuestions]
  );

  return {
    isLoading,
    generatedQuestions,
    addQuestion,
    removeQuestion,
    editQuestion,
    generateQuestions,
  };
};

export { useFormQuestionViewModel };
