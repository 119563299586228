import { Action, Dispatch } from "redux";

import IClasseModel from "@app/features/Classe/domain/models/IClasseModel";

import {
  GET_CLASSES,
  LOADING_CLASSES,
  SET_CURRENT_CLASSE,
} from "@app/features/Classe/data/classeActionsType";
import { setCurrentLanguageAction } from "@app/features/Language/data/languageActions";
import { dispatchStore } from "@app/config/store";

export const setClassesAction =
  (classes?: IClasseModel[]) => (dispatch: Dispatch<Action>) => {
    dispatch({ type: GET_CLASSES, payload: classes });
  };

export const setCurrentClasseAction =
  (classe: IClasseModel | null) => (dispatch: Dispatch<Action>) => {
    dispatch({ type: SET_CURRENT_CLASSE, payload: classe });
    if (classe) {
      dispatchStore(setCurrentLanguageAction(classe.language));
    }
  };

export const setLoadingClassesAction =
  (value: boolean) =>
  (dispatch: Dispatch<Action>): Action =>
    dispatch({ type: LOADING_CLASSES, payload: value });
