import { useCallback, useState } from "react";

import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";
import { ITagRepository } from "@app/features/Tag/data/tagRepository";

import listResourcesUseCase from "@app/features/Resource/domain/use-cases/listResourcesUseCase";
import listTagsUseCase from "@app/features/Tag/domain/use-cases/listTagsUseCase";

import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";

import handleApplicationError from "@app/handlers/handleApiError";
import { INVITE_STATUS } from "@app/constants/enums";
import IPaginationModel, { IPageModel } from "@app/constants/interfaces";
import { TListResourcesSearchParams } from "@app/services/resource";
import ITagModel from "@app/features/Tag/domain/models/ITagModel";

export type TInviteClasseViewModel = {
  classeId: number;
  inviteStatus: INVITE_STATUS;
};

const useFormLessonViewModel = (
  resourceRepository: IResourceRepository,
  tagRepository: ITagRepository
) => {
  const [resources, setResources] = useState<IPaginationModel<IResourceModel>>({
    count: 0,
    data: [],
  });
  const [isLoading, setLoading] = useState(false);
  const [tags, setTags] = useState<ITagModel[]>([]);

  const listResources = useCallback(
    async (
      companyId: number,
      pageConfig: IPageModel,
      filterParams?: TListResourcesSearchParams
    ) => {
      try {
        setLoading(true);
        const data = await listResourcesUseCase(
          {
            listResources: resourceRepository.listResources,
          },
          companyId,
          pageConfig,
          filterParams
        );
        setResources(data);
        setLoading(false);
      } catch (error) {
        handleApplicationError.handleError(error);
      }
    },
    [resourceRepository.listResources]
  );

  const listTags = useCallback(
    async (companyId: number, languageId: number) => {
      try {
        setLoading(true);

        const data = await listTagsUseCase(
          {
            listTags: tagRepository.listTags,
          },
          companyId,
          undefined,
          { languageId }
        );
        setTags(data as ITagModel[]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [tagRepository.listTags]
  );

  return { isLoading, resources, listResources, tags, listTags };
};

export { useFormLessonViewModel };
