import React, { useCallback, useEffect, useMemo } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useCollectionRepository } from "@app/features/Collection/data/collectionRepository";
import { useEditCollectionViewModel } from "@app/features/Collection/views/Edit/editCollectionViewModel";
import collectionService from "@app/services/collection";

import StandardTemplate from "@app/components/templates/Standard";
import LoadingLock from "@app/components/molecules/LoadingLock";
import FooterActions from "@app/components/molecules/FooterActions";

import CollectionForm, {
  TCollectionForm,
} from "@app/features/Collection/views/Form";

import { RootState } from "@app/config/store";

const EditCollection = () => {
  const { id } = useParams();
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const collectionRepository = useCollectionRepository(collectionService);
  const { getCollection, isLoading, editCollection, collection } =
    useEditCollectionViewModel(collectionRepository);

  const handleSave = useCallback(
    (data: TCollectionForm) => {
      if (currentCompany) {
        editCollection({
          id: Number(id),
          languageId: data.languageId,
          name: data.name,
          companyId: currentCompany.id,
        });
      }
    },
    [editCollection, currentCompany, id]
  );

  useEffect(() => {
    if (id) {
      getCollection(Number(id));
    }
  }, [id, getCollection]);

  const tagData = useMemo(() => {
    if (collection) {
      return { ...collection, languageId: collection.language.id };
    }
  }, [collection]);

  return (
    <StandardTemplate title="Editar coleção" showBackButton>
      <LoadingLock isLoading={isLoading} />
      <CollectionForm
        dataForm={tagData}
        onValidateSuccess={handleSave}
        footerActions={<FooterActions />}
      />
    </StandardTemplate>
  );
};

export default EditCollection;
