import { ILanguageRepository } from "@app/features/Language/data/languageRepository";

import ILanguageModel from "@app/features/Language/domain/models/ILanguageModel";
import ILanguageEntity from "@app/features/Language/data/ILanguageEntity";

import {
  setLanguagesAction,
  setLoadingLanguagesAction,
} from "@app/features/Language/data/languageActions";

import { dispatchStore } from "@app/config/store";

const getAvailableLanguagesUseCase = async (
  repository: Pick<ILanguageRepository, "getAvailableLanguages">
): Promise<void> => {
  dispatchStore(setLoadingLanguagesAction(true));

  const languagesEntity = await repository.getAvailableLanguages();
  const languagesModel = mapToDomain(languagesEntity);

  dispatchStore(setLanguagesAction(languagesModel));
  dispatchStore(setLoadingLanguagesAction(false));
};

const mapToDomain = (entities: ILanguageEntity[]): ILanguageModel[] => {
  const languagesModel = entities.map((language) => language);
  return languagesModel;
};

export default getAvailableLanguagesUseCase;
