import React, {
  forwardRef,
  RefObject,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { Tabs, Tab, Box, Toolbar, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";

import { useResourceRepository } from "@app/features/Resource/data/resourceRepository";
import {
  useCreateResourceViewModel,
  TCreateRosourceViewModel,
} from "@app/features/Resource/views/Create/createResourceViewModel";
import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";

import ListResourcesForm from "@app/features/Lesson/view/Form/ListResourcesForm";
import ResourcesForm, {
  TResourceFormRef,
} from "@app/features/Resource/views/Form/ResourceForm";

import { OptionType } from "@app/components/organisms/ResourceType";
import Drawer, { IDrawerRef } from "@app/components/molecules/Drawer";
import TabPanel from "@app/components/templates/TabPanel";

import { RESOURCE_TYPE } from "@app/constants/enums";
import theme from "@app/config/theme";
import resourceService from "@app/services/resource";
import { RootState } from "@app/config/store";
import { TAcceptedFileType } from "@app/components/atoms/FileInput";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export type DrawerResourceRef = IDrawerRef;

type DrawerResourceProps = {
  ref?: RefObject<DrawerResourceRef>;
  title: string;
  resourcesAllowed: RESOURCE_TYPE[];
  maxItems: number;
  uploadAllowedTypes?: OptionType;
  onAddItems: (items: IResourceModel[]) => void;
  acceptedFiles: TAcceptedFileType;
  onCancel?: () => void;
};

const DrawerResource = forwardRef<unknown, DrawerResourceProps>(
  (
    {
      resourcesAllowed,
      title,
      maxItems,
      uploadAllowedTypes,
      onAddItems,
      acceptedFiles,
      onCancel,
    }: DrawerResourceProps,
    ref
  ) => {
    const { currentCompany } = useSelector((state: RootState) => state.company);
    const drawerResourceRef = useRef<IDrawerRef>();
    const resourceFormRef = useRef<TResourceFormRef>();

    const [currentTab, setCurrentTab] = useState(0);
    const [selectedItems, setSelectedItems] = useState<IResourceModel[]>([]);

    const resourceRepository = useResourceRepository(resourceService);

    const { createResource } = useCreateResourceViewModel(resourceRepository);

    const handleSelectResourceDocument = useCallback(
      (data: IResourceModel[]) => {
        setSelectedItems(data);
      },
      []
    );

    const openDrawer = useCallback(() => {
      drawerResourceRef.current?.openDrawer();
    }, []);

    const closeDrawer = useCallback(() => {
      drawerResourceRef.current?.closeDrawer();
      setCurrentTab(0);
      if (onCancel) {
        onCancel();
      }
    }, [onCancel]);

    const handleChangeTab = useCallback(
      (event: React.SyntheticEvent<Element, Event>, value: number) => {
        setCurrentTab(value);
      },
      []
    );

    const handleAddItems = useCallback(async () => {
      onAddItems(selectedItems);
      drawerResourceRef.current?.closeDrawer();
    }, [onAddItems, selectedItems]);

    const handleCreateResource = useCallback(async () => {
      const resources = await resourceFormRef.current?.validate();

      if (currentCompany && resources?.length) {
        const { id } = currentCompany;
        const viewModel = resources.map(
          (r): TCreateRosourceViewModel => ({
            languageId: r.languageId,
            name: r.name,
            url: r.url,
            fileKey: r.fileKey,
            size: r.size,
          })
        );
        const data = await createResource(viewModel, id, false);

        if (data) {
          onAddItems(data);
          drawerResourceRef.current?.closeDrawer();
        }
      }
    }, [createResource, currentCompany, onAddItems]);

    useImperativeHandle(ref, () => ({ openDrawer, closeDrawer }), [
      openDrawer,
      closeDrawer,
    ]);

    return (
      <Drawer ref={drawerResourceRef} drawerPosition="bottom">
        <Box
          sx={{ position: "sticky", overflow: "hidden", top: 0, zIndex: 9999 }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Typography
              variant="h6"
              id="tableTitle"
              color="white"
              component="div"
            >
              {`${title} - Selecione até ${maxItems} item(s)`}
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="inherit"
                size="small"
                onClick={closeDrawer}
              >
                Cancelar
              </Button>
              {currentTab === 0 && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{ ml: 2 }}
                  disabled={!selectedItems.length}
                  onClick={handleAddItems}
                >
                  {`Adicionar ${
                    selectedItems.length ? selectedItems.length : ""
                  } item(s)`}
                </Button>
              )}
              {currentTab === 1 && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{ ml: 2 }}
                  onClick={handleCreateResource}
                >
                  {`Adicionar recurso`}
                </Button>
              )}
            </Box>
          </Toolbar>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            sx={{ backgroundColor: theme.palette.background.paper }}
          >
            <Tab label={"Biblioteca"} {...a11yProps(0)} />
            <Tab label={"Fazer Upload de recurso"} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={currentTab} index={0}>
          <Box
            sx={{ backgroundColor: "#FFF", height: "70vh" }}
            role="presentation"
          >
            <ListResourcesForm
              maxItems={maxItems}
              onSelectItems={handleSelectResourceDocument}
              onCancel={closeDrawer}
              resourceTypes={resourcesAllowed}
            />
          </Box>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Box
            sx={{
              backgroundColor: "#FFF",
              height: "70vh",
            }}
            role="presentation"
          >
            <ResourcesForm
              ref={resourceFormRef}
              maxItems={maxItems}
              predefinedResourceType={uploadAllowedTypes}
              allowSelectLanguage={false}
              footerActions={null}
              acceptedFiles={acceptedFiles}
            />
          </Box>
        </TabPanel>
      </Drawer>
    );
  }
);

export default DrawerResource;
