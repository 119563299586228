import React, { useCallback, useEffect, useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  TextField,
  Box,
  InputAdornment,
  Divider,
  Grid,
  Button,
  Tooltip,
} from "@mui/material";

import { FiChevronDown, FiPlus, FiSave, FiTrash } from "react-icons/fi";
import StickyHeaderTable from "../StickyHeaderTable";

import IModuleModel from "@app/features/Module/domain/models/IModuleModel";
import ILessonModel from "@app/features/Lesson/domain/models/ILessonModel";
import { IOrderModel } from "@app/constants/interfaces";
import AlertDialog from "@app/components/molecules/AlertDialog";

export type onChangeModuleName = {
  moduleId: number;
  value: string;
  onSuccessCallback?: () => void;
};

type ModuleAccordionProps = {
  module: IModuleModel;
  disabled: boolean;
  onExpandModule: (module: IModuleModel, isExpanded: boolean) => void;
  onDeleteLesson: (module: IModuleModel, lesson: ILessonModel) => void;
  onEditLesson: (moduleId: number, lesson: ILessonModel) => void;
  onChangeOrderLesson: (
    moduleId: number,
    lessonId: number,
    orderEvent: IOrderModel
  ) => void;
  onCreateLesson: (module: IModuleModel) => void;
  onChangeModuleName: ({ moduleId, value }: onChangeModuleName) => void;
  onArchieveModule: (module: IModuleModel) => void;
};

const ModuleAccordion = ({
  module,
  disabled,
  onCreateLesson,
  onExpandModule,
  onChangeModuleName,
  onChangeOrderLesson,
  onDeleteLesson,
  onEditLesson,
  onArchieveModule,
}: ModuleAccordionProps) => {
  const [openArchieveDialog, setArchieveDialog] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [moduleName, setModuleName] = useState(module.name);

  const handleCreateLesson = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onCreateLesson(module);
    },
    [module, onCreateLesson]
  );

  const handleSaveModuleName = () => {
    onChangeModuleName({
      moduleId: module.id,
      value: moduleName,
      onSuccessCallback: () => setEnableEdit(false),
    });
  };

  const handleEditLesson = useCallback(
    (lesson: ILessonModel) => {
      onEditLesson(module.id, lesson);
    },
    [onEditLesson, module]
  );

  const handleDeleteModule = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setArchieveDialog(true);
    },
    []
  );

  const handleOrderChange = useCallback(
    (_: ILessonModel[], item: ILessonModel, orderEvent: IOrderModel) => {
      onChangeOrderLesson(module.id, item.id, orderEvent);
    },
    [onChangeOrderLesson, module.id]
  );

  const handleChangeModuleName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setModuleName(event.target.value);
    },
    []
  );

  const handleChangeExpanded = useCallback(
    (_: React.SyntheticEvent, isExpanded: boolean) => {
      onExpandModule(module, isExpanded);
    },
    [onExpandModule, module]
  );

  const handleDeleteLesson = useCallback(
    (lesson: ILessonModel) => {
      onDeleteLesson(module, lesson);
    },
    [module, onDeleteLesson]
  );

  const handleAgreeArchieveModule = useCallback(() => {
    onArchieveModule(module);
  }, [module, onArchieveModule]);

  const handleDisagreeArchieveModule = useCallback(() => {
    setArchieveDialog(false);
  }, []);

  useEffect(() => {
    setEnableEdit(module.name !== moduleName);
  }, [module.name, moduleName]);

  useEffect(() => {
    setModuleName(module.name);
  }, [module.name]);

  return (
    <>
      <AlertDialog
        open={openArchieveDialog}
        onAgree={handleAgreeArchieveModule}
        onDisagree={handleDisagreeArchieveModule}
        title={"Remover módulo"}
        description={
          "Tem certeza que deseja remover este módulo? Todas as aulas também serão removidas e aluno não poderão mais acessar esse módulo"
        }
      />
      <Accordion
        disabled={disabled}
        onChange={handleChangeExpanded}
        sx={{
          "& .Mui-focusVisible": { backgroundColor: "#FFF" },
        }}
      >
        <AccordionSummary
          expandIcon={
            <IconButton size="medium" color="secondary">
              <FiChevronDown />
            </IconButton>
          }
          focusRipple={true}
          focusVisibleClassName="none"
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            "& .MuiAccordionSummary-root": { backgroundColor: "#FFF" },
            "& .Mui-focusVisible": { backgroundColor: "#FFF" },
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box ml={2}>
                <TextField
                  label="Nome do módulo"
                  value={moduleName}
                  variant="standard"
                  onChange={handleChangeModuleName}
                  onClick={(event) => event.stopPropagation()}
                  InputProps={{
                    endAdornment: enableEdit && (
                      <InputAdornment position="end">
                        <Tooltip title="Salvar nome do módulo">
                          <IconButton
                            onClick={handleSaveModuleName}
                            color="success"
                          >
                            <FiSave />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            {!enableEdit && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<FiTrash />}
                  onClick={handleDeleteModule}
                  size="small"
                >
                  Remover módulo
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<FiPlus />}
                  onClick={handleCreateLesson}
                  size="small"
                  sx={{ ml: 1 }}
                >
                  Adicionar aula
                </Button>
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <StickyHeaderTable
            identifierColumnKey="id"
            allowReordering
            dialogMessage={{
              title: "Remover aula?",
              description: "Tem certeza que deseja remover esta aula?",
            }}
            count={module.lessons?.length || 0}
            onEdit={handleEditLesson}
            onOrderChange={handleOrderChange}
            onDelete={handleDeleteLesson}
            columns={[
              {
                id: "image",
                label: "Capa da aula",
                component: "AVATAR",
              },
              {
                id: "name",
                label: "Nome",
                align: "left",
              },
            ]}
            rows={module.lessons || []}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ModuleAccordion;
