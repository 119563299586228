import ITagEntity from "@app/features/Tag/data/ITagEntity";

import { TCreateTagRemoteData } from "@app/services/tag/remote-types/TCreateTagRemoteData";
import { TEditTagRemoteData } from "@app/services/tag/remote-types/TEditTagRemoteData";

import IPaginationModel, { IPageModel } from "@app/constants/interfaces";
import API_SERVICES from "@app/services/constants";
import api from "@app/config/api";

export type TListTagsSearchParams = {
  name?: string;
  color?: string;
  languageId?: number;
};

export interface ITagService {
  createTagService: (data: TCreateTagRemoteData) => Promise<void>;
  listTagsService: (
    companyId: number,
    pageConfig?: IPageModel,
    filterParams?: TListTagsSearchParams
  ) => Promise<IPaginationModel<ITagEntity>>;
  editTagService: (data: TEditTagRemoteData) => Promise<void>;
  deleteTagService: (tagId: number) => Promise<void>;
  getTagService: (tagId: number) => Promise<ITagEntity>;
}

const createTagService = async (data: TCreateTagRemoteData): Promise<void> =>
  api.post(API_SERVICES.TAG_SERVICES.CREATE, data).then((res) => res.data);

const listTagsService = async (
  companyId: number,
  pageConfig?: IPageModel,
  filterParams?: TListTagsSearchParams
): Promise<IPaginationModel<ITagEntity>> =>
  api
    .get(API_SERVICES.TAG_SERVICES.LIST(companyId), {
      params: { ...pageConfig, ...filterParams },
    })
    .then((res) => res.data);

const editTagService = async (data: TEditTagRemoteData): Promise<void> =>
  api
    .put(API_SERVICES.TAG_SERVICES.EDIT(data.id), data)
    .then((res) => res.data);

const deleteTagService = async (tagId: number): Promise<void> =>
  api.delete(API_SERVICES.TAG_SERVICES.DELETE(tagId)).then((res) => res.data);

const getTagService = async (tagId: number): Promise<ITagEntity> =>
  api.get(API_SERVICES.TAG_SERVICES.GET(tagId)).then((res) => res.data);

const tagService: ITagService = {
  createTagService,
  listTagsService,
  editTagService,
  deleteTagService,
  getTagService,
};

export default tagService;
