import { useCallback, useState } from "react";

import createCollectionUseCase from "@app/features/Collection/domain/use-cases/createCollectionUseCase";

import { ICollectionRepository } from "@app/features/Collection/data/collectionRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";
import { useNavigate } from "react-router-dom";

export type TCreateCollectionViewModel = {
  name: string;
  companyId: number;
  languageId: number;
};

const useCreateCollectionViewModel = (repository: ICollectionRepository) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const createCollection = useCallback(
    async (data: TCreateCollectionViewModel) => {
      try {
        setLoading(true);

        await createCollectionUseCase(
          {
            createCollection: repository.createCollection,
          },
          data
        );
        setLoading(false);
        showNotification("Coleção criada com sucesso", "SUCCESS");
        navigate(-1);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.createCollection, navigate]
  );

  return { isLoading, createCollection };
};

export { useCreateCollectionViewModel };
