import React, { useCallback } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, Chip, IconButton, Stack } from "@mui/material";
import ICollectionModel from "@app/features/Collection/domain/models/ICollectionModel";
import { FiEdit, FiTrash } from "react-icons/fi";

type CardCollectionProps = {
  data: ICollectionModel;
  onEdit?: (data: ICollectionModel) => void;
  onSelect: (data: ICollectionModel) => void;
  onDelete?: (data: ICollectionModel) => void;
};

const CardCollection = ({
  data,
  onDelete,
  onEdit,
  onSelect,
}: CardCollectionProps) => {
  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(data);
    }
  }, [onDelete, data]);

  const handleSelect = useCallback(() => {
    onSelect(data);
  }, [onSelect, data]);

  const handleEdit = useCallback(() => {
    if (onEdit) {
      onEdit(data);
    }
  }, [onEdit, data]);

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{ mb: 2.5 }}
        >
          <Typography variant="h5" component="div">
            {data.name}
          </Typography>
          {onEdit && (
            <IconButton color="primary" onClick={handleEdit} size="small">
              <FiEdit />
            </IconButton>
          )}
        </Stack>

        <Chip
          avatar={<Avatar src={data?.language.image} />}
          label={data?.language.name}
        />
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between" }}>
        <Button size="small" variant="outlined" onClick={handleSelect}>
          Selecionar
        </Button>
        {onDelete && (
          <IconButton color="error" onClick={handleDelete}>
            <FiTrash />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};

export default CardCollection;
