import ICollectionEntity from "@app/features/Collection/data/ICollectionEntity";
import ILessonEntity from "@app/features/Lesson/data/ILessonEntity";

import { TCreateCollectionRemoteData } from "@app/services/collection/remote-types/TCreateCollectionRemoteData";
import { TEditCollectionRemoteData } from "@app/services/collection/remote-types/TEditCollectionRemoteData";

import IPaginationModel, { IPageModel } from "@app/constants/interfaces";
import API_SERVICES from "@app/services/constants";
import api from "@app/config/api";

export type TListCollectionsSearchParams = {
  name?: string;
  languageId?: number;
};

export type TListPagesFromCollectionSearchParams = {
  name?: string;
};

export interface ICollectionService {
  createCollectionService: (data: TCreateCollectionRemoteData) => Promise<void>;
  listCollectionsService: (
    companyId: number,
    pageConfig?: IPageModel,
    filterParams?: TListCollectionsSearchParams
  ) => Promise<ICollectionEntity[]>;
  editCollectionService: (data: TEditCollectionRemoteData) => Promise<void>;
  deleteCollectionService: (collectionId: number) => Promise<void>;
  getCollectionService: (collectionId: number) => Promise<ICollectionEntity>;
  listLessonsFromCollectionService(
    collectionId: number,
    pageConfig?: IPageModel,
    filterParams?: TListCollectionsSearchParams
  ): Promise<IPaginationModel<ILessonEntity>>;
}

const createCollectionService = async (
  data: TCreateCollectionRemoteData
): Promise<void> =>
  api
    .post(API_SERVICES.COLLECTION_SERVICES.CREATE, data)
    .then((res) => res.data);

const listCollectionsService = async (
  companyId: number,
  pageConfig?: IPageModel,
  filterParams?: TListCollectionsSearchParams
): Promise<ICollectionEntity[]> =>
  api
    .get(API_SERVICES.COLLECTION_SERVICES.LIST(companyId), {
      params: { ...pageConfig, ...filterParams },
    })
    .then((res) => res.data);

const editCollectionService = async (
  data: TEditCollectionRemoteData
): Promise<void> =>
  api
    .put(API_SERVICES.COLLECTION_SERVICES.EDIT(data.id), data)
    .then((res) => res.data);

const deleteCollectionService = async (collectionId: number): Promise<void> =>
  api
    .delete(API_SERVICES.COLLECTION_SERVICES.DELETE(collectionId))
    .then((res) => res.data);

const getCollectionService = async (
  collectionId: number
): Promise<ICollectionEntity> =>
  api
    .get(API_SERVICES.COLLECTION_SERVICES.GET(collectionId))
    .then((res) => res.data);

const listLessonsFromCollectionService = async (
  collectionId: number
): Promise<IPaginationModel<ILessonEntity>> =>
  api
    .get(
      API_SERVICES.COLLECTION_SERVICES.GET_LESSONS_FROM_COLLECTION(collectionId)
    )
    .then((res) => res.data);

const collectionService: ICollectionService = {
  createCollectionService,
  listCollectionsService,
  editCollectionService,
  deleteCollectionService,
  getCollectionService,
  listLessonsFromCollectionService,
};

export default collectionService;
