import { ITagRepository } from "@app/features/Tag/data/tagRepository";

import ITagModel from "@app/features/Tag/domain/models/ITagModel";
import IPaginationModel, { IPageModel } from "@app/constants/interfaces";

import ITagEntity from "@app/features/Tag/data/ITagEntity";
import { TListTagsSearchParams } from "@app/services/tag";

const listTagsUseCase = async (
  repository: Pick<ITagRepository, "listTags">,
  companyId: number,
  pageConfig?: IPageModel,
  filterParams?: TListTagsSearchParams
): Promise<IPaginationModel<ITagModel> | ITagModel[]> => {
  const tagsEntity = await repository.listTags(
    companyId,
    pageConfig,
    filterParams
  );
  const domain = mapToDomain(tagsEntity.data);

  if (pageConfig) {
    return { count: tagsEntity.count, data: domain };
  }

  return domain;
};

const mapToDomain = (tagEntity: ITagEntity[]): ITagModel[] => {
  const domain = tagEntity.map((tag): ITagModel => {
    const { id, name, color, language } = tag;

    return {
      id,
      name,
      color,
      language,
    };
  });

  return domain;
};

export default listTagsUseCase;
