import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Chip, Grid, Stack } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import { FiPlus } from "react-icons/fi";

import { useListCollectionsViewModel } from "@app/features/Collection/views/List/ListCollections/listCollectionViewModel";
import { useCollectionRepository } from "@app/features/Collection/data/collectionRepository";
import collectionService, {
  TListCollectionsSearchParams,
} from "@app/services/collection";
import { setCurrentLanguageAction } from "@app/features/Language/data/languageActions";

import ICollectionModel from "@app/features/Collection/domain/models/ICollectionModel";

import DrawerFilter, {
  IDrawerFilterRef,
  TFilterPayload,
} from "@app/components/organisms/DrawerFilter";
import Standard from "@app/components/templates/Standard";
import CardCollection from "@app/components/organisms/CardCollection";
import LoadingLock from "@app/components/molecules/LoadingLock";

import { dispatchStore, RootState } from "@app/config/store";
import { ROLE } from "@app/constants/enums";
import useRestricted from "@app/hooks/useRestricted";
import { IPageModel } from "@app/constants/interfaces";
import { ROWS_PER_PAGE_OPTIONS } from "@app/constants/optionsSelect";
import EmptyState from "@app/components/organisms/EmptyState";

const ListCollections = () => {
  const navigate = useNavigate();
  const { isAllowedTo } = useRestricted();
  const draweFilterRef = useRef<IDrawerFilterRef>();

  const { languages } = useSelector((state: RootState) => state.language);
  const { collections, loading } = useSelector(
    (state: RootState) => state.collection
  );
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const [filters, setFilters] = useState<TFilterPayload[]>([]);
  const [paginationConfig] = useState<IPageModel>({
    page: 0,
    amount: ROWS_PER_PAGE_OPTIONS.MIN,
  });

  const collectionRepository = useCollectionRepository(collectionService);
  const { listCollections, removeCollection } =
    useListCollectionsViewModel(collectionRepository);

  const navigateToCreateCollection = useCallback(() => {
    navigate("/admin/collections/create");
  }, [navigate]);

  const handleDeleteCollection = useCallback(
    (collection: ICollectionModel) => {
      if (currentCompany) {
        removeCollection(collection.id, currentCompany.id, paginationConfig);
      }
    },
    [removeCollection, currentCompany, paginationConfig]
  );

  const handleSetFilter = useCallback((values: TFilterPayload[]) => {
    setFilters([...values]);
  }, []);

  const removeFilter = useCallback(
    (filter: TFilterPayload) => {
      let newFilters = filters.filter((f) => f.id !== filter.id);
      setFilters([...newFilters]);
    },
    [filters]
  );

  const handleEditCollection = useCallback(
    (collection: ICollectionModel) => {
      navigate(`/admin/collections/edit/${collection.id}`);
    },
    [navigate]
  );

  const handleSelectCollection = useCallback(
    (collection: ICollectionModel) => {
      navigate(`/admin/collections/${collection.id}/lessons`, {
        state: { collection },
      });
      dispatchStore(setCurrentLanguageAction(collection.language));
    },
    [navigate]
  );

  const languagesData = useMemo(() => {
    return languages.map((item): { label: string; value: string } => {
      return {
        label: item.name,
        value: item.id.toString(),
      };
    });
  }, [languages]);

  useEffect(() => {
    let filtersData: TListCollectionsSearchParams = {};
    filters.forEach((f) => {
      if (f.value) {
        filtersData = { [f.id]: f.value };
      }
    });
    if (currentCompany) {
      listCollections(currentCompany.id, paginationConfig, filtersData);
    }
  }, [listCollections, currentCompany, paginationConfig, filters]);

  return (
    <>
      <Standard
        title="Acervo de aulas"
        primaryButton={{
          textButton: "Novo",
          iconButton: <FiPlus />,
          isVisible: !!currentCompany && isAllowedTo([ROLE.OWNER, ROLE.ADMIN]),
          onClickButton: navigateToCreateCollection,
        }}
        secondaryButton={{
          isVisible: true,
          textButton: "Filtro",
          iconButton: <FilterAlt />,
          onClickButton: draweFilterRef.current?.openDrawer,
        }}
      >
        {!!filters.length && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ mb: 3 }}
          >
            {filters.map((filter) => (
              <Chip
                variant="outlined"
                label={`${filter.labelFilter}: ${filter.selectValueLabel}`}
                onDelete={() => removeFilter(filter)}
              />
            ))}
          </Stack>
        )}
        {!loading && !collections.length && (
          <EmptyState
            message="Você ainda não cadastrou nenhuma coleção."
            onClickButton={navigateToCreateCollection}
            textButton="Cadastrar coleção"
          />
        )}

        {loading && <LoadingLock isLoading={loading} />}
        <Grid container spacing={4}>
          {collections.map((collection) => (
            <Grid key={collection.id} item xs={12} sm={3}>
              <CardCollection
                data={collection}
                onDelete={handleDeleteCollection}
                onEdit={handleEditCollection}
                onSelect={handleSelectCollection}
              />
            </Grid>
          ))}
        </Grid>
      </Standard>
      {/* {collections.count > collections.data.length && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 5,
          }}
        >
          <Button variant="outlined" onClick={handleChangePage}>
            Carregar mais
          </Button>
        </Box>
      )} */}

      <DrawerFilter
        ref={draweFilterRef}
        onFilter={handleSetFilter}
        fields={[
          {
            id: "name",
            label: "Nome",
            type: "TEXT",
          },
          {
            id: "language",
            label: "Idioma da coleção",
            type: "SELECT",
            options: languagesData,
          },
        ]}
      />
    </>
  );
};

export default ListCollections;
