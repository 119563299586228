import { ICollectionRepository } from "@app/features/Collection/data/collectionRepository";
import { TEditCollectionViewModel } from "@app/features/Collection/views/Edit/editCollectionViewModel";

const editCollectionUseCase = async (
  repository: Pick<ICollectionRepository, "editCollection">,
  data: TEditCollectionViewModel
) => {
  await repository.editCollection(data);
};

export default editCollectionUseCase;
