import React, { ReactNode, useCallback, useEffect, useState } from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Avatar,
  Box,
  Checkbox,
} from "@mui/material";
import _ from "lodash";
import Lottie from "lottie-react";
import { FiEdit, FiTrash2, FiMenu, FiImage } from "react-icons/fi";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import { ROWS_PER_PAGE_OPTIONS } from "@app/constants/optionsSelect";
import { lotties } from "@app/assets";
import { Subtitle } from "@app/components/atoms/Text";
import { IOrderModel } from "@app/constants/interfaces";
import AlertDialog from "@app/components/molecules/AlertDialog";

export interface Column {
  id: string;
  label: string;
  width?: number;
  component?: "TEXT" | "AVATAR" | "CUSTOM";
  customComponent?: (data: { [key: string]: any }) => ReactNode;
  align?: "left" | "center" | "right";
  format?: (value: number) => string;
}

type StickyHeaderTableProps<T> = {
  pagination?: boolean;
  identifierColumnKey: string;
  allowReordering?: boolean;
  allowSelection?: boolean;
  columns: Column[];
  emptyMessage?: string;
  showHeader?: boolean;
  rows: T[];
  count: number;
  dialogMessage?: { title: string; description: string };
  itemsSelected?: number[];
  onEdit?: (data: T) => void;
  onDelete?: (data: T) => void;
  onPageChange?: (newPage: number, rowsPerPage: number) => void;
  onOrderChange?: (data: T[], item: T, orderEvent: IOrderModel) => void;
  onSelectionChange?: (selectedIds: number[], selectedRows: T[]) => void;
};

const StickyHeaderTable = <T extends { [key: string]: any }>({
  columns,
  rows,
  count,
  identifierColumnKey,
  allowReordering,
  allowSelection,
  pagination,
  dialogMessage,
  emptyMessage = "Nenhum item encontrado.",
  showHeader = true,
  itemsSelected = [],
  onDelete,
  onEdit,
  onPageChange,
  onOrderChange,
  onSelectionChange,
}: StickyHeaderTableProps<T>) => {
  const [data, setData] = useState<T[]>(rows);
  const [page, setPage] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS.MIN);
  const [selectedItem, setSelectedItem] = useState<T>();

  const handleChangePage = useCallback(
    (_: unknown, newPage: number) => {
      setPage(newPage);
      if (onPageChange) onPageChange(newPage, rowsPerPage);
    },
    [onPageChange, rowsPerPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newRowsPerPage = Number(event.target.value);
      setRowsPerPage(newRowsPerPage);
      if (onPageChange) onPageChange(0, newRowsPerPage);
      setPage(0);
    },
    [onPageChange]
  );

  const handleDragEnd = useCallback(
    (event: DropResult) => {
      if (!event.destination) return;
      const result = Array.from(data);
      const [removed] = result.splice(event.source.index, 1);
      result.splice(event.destination.index, 0, removed);
      setData([...result]);

      if (onOrderChange) {
        onOrderChange(result, removed, {
          source: event.source.index + 1,
          destination: event.destination.index + 1,
        });
      }
    },
    [data, onOrderChange]
  );

  const getCellComponent = useCallback(
    (row: { [key: string]: any }, column: Column) => {
      const data = _.get(row, column.id);
      switch (column.component) {
        case "AVATAR":
          return (
            <Avatar alt="Image" src={data}>
              <FiImage />
            </Avatar>
          );

        case "CUSTOM":
          if (column.customComponent) {
            return column.customComponent(row);
          }
          return data;

        default:
          const value = row[column.id];
          if (column.format) {
            return column.format(value);
          }

          return data;
      }
    },
    []
  );

  const getSelectedRows = useCallback(
    (selectedItems: number[]) =>
      data.filter((d) => selectedItems.includes(d[identifierColumnKey])),
    [data, identifierColumnKey]
  );

  const handleSelect = useCallback(
    (id: number) => {
      const selectedIndex = itemsSelected?.indexOf(id);
      let newSelected: number[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(itemsSelected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(itemsSelected.slice(1));
      } else if (selectedIndex === itemsSelected.length - 1) {
        newSelected = newSelected.concat(itemsSelected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          itemsSelected.slice(0, selectedIndex),
          itemsSelected.slice(selectedIndex + 1)
        );
      }

      if (onSelectionChange) {
        const selectedRows = getSelectedRows(newSelected);
        onSelectionChange(newSelected, selectedRows);
      }
    },
    [onSelectionChange, getSelectedRows, itemsSelected]
  );

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newSelectedIds = [];
      if (event.target.checked) {
        newSelectedIds = data.map((n) => n.id);
      }
      if (onSelectionChange) {
        const selectedRows = getSelectedRows(newSelectedIds);
        onSelectionChange(newSelectedIds, selectedRows);
      }
    },
    [data, onSelectionChange, getSelectedRows]
  );

  const handleAgreeDialog = useCallback(() => {
    if (selectedItem) {
      onDelete?.(selectedItem);
    }
    setSelectedItem(undefined);
    setOpenDeleteDialog(false);
  }, [onDelete, selectedItem]);

  const handleDisagreeDialog = useCallback(() => {
    setOpenDeleteDialog(false);
    setSelectedItem(undefined);
  }, []);

  const handleShowDialog = useCallback((data: T) => {
    setOpenDeleteDialog(true);
    setSelectedItem(data);
  }, []);

  const isSelected = useCallback(
    (id: number) => itemsSelected.indexOf(id) !== -1,
    [itemsSelected]
  );

  useEffect(() => {
    setData(rows);
  }, [rows]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <AlertDialog
        open={openDeleteDialog}
        onAgree={handleAgreeDialog}
        onDisagree={handleDisagreeDialog}
        title={dialogMessage?.title || "Remover item"}
        description={
          dialogMessage?.description ||
          "Tem certeza que deseja remover este item?"
        }
      />
      <TableContainer sx={{ maxHeight: "62vh" }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Table stickyHeader aria-label="sticky table">
            {showHeader && (
              <TableHead>
                <TableRow>
                  {allowSelection && !pagination && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          itemsSelected.length > 0 &&
                          itemsSelected.length < count
                        }
                        checked={count > 0 && itemsSelected.length === count}
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>
                  )}
                  {allowReordering && <TableCell />}
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        width: column.width,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  {!!onEdit && <TableCell />}
                  {!!onDelete && <TableCell />}
                </TableRow>
              </TableHead>
            )}

            <Droppable droppableId="tbody">
              {(provider) => (
                <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                  {data.map((row, index) => {
                    const isItemSelected = isSelected(row[identifierColumnKey]);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <Draggable
                        key={index}
                        index={index}
                        isDragDisabled={!allowReordering}
                        draggableId={String(index)}
                      >
                        {(providedDraggable) => (
                          <TableRow
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                            hover
                            sx={{ backgroundColor: "#FFF" }}
                            role="checkbox"
                            tabIndex={-1}
                          >
                            {allowReordering && (
                              <TableCell
                                align="left"
                                style={{
                                  width: 10,
                                }}
                              >
                                <FiMenu />
                              </TableCell>
                            )}
                            {allowSelection && (
                              <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={() =>
                                    handleSelect(row[identifierColumnKey])
                                  }
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>
                            )}
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  width: column.width,
                                }}
                              >
                                {getCellComponent(row, column)}
                              </TableCell>
                            ))}
                            {!!onEdit && (
                              <TableCell align="right">
                                <IconButton
                                  color="secondary"
                                  component="span"
                                  size="small"
                                  disabled={!row[identifierColumnKey]}
                                  onClick={() => onEdit(row)}
                                >
                                  <FiEdit />
                                </IconButton>
                              </TableCell>
                            )}
                            {!!onDelete && (
                              <TableCell align="right" width="5%">
                                <IconButton
                                  color="primary"
                                  component="span"
                                  size="small"
                                  onClick={() => handleShowDialog(row)}
                                >
                                  <FiTrash2 />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                      </Draggable>
                    );
                  })}
                  {provider.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </TableContainer>
      {!rows.length && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            pb: 2,
          }}
        >
          <Lottie
            animationData={lotties.emptyState}
            loop={true}
            style={{
              width: 400,
              height: 200,
            }}
          />
          {emptyMessage && <Subtitle>{emptyMessage}</Subtitle>}
        </Box>
      )}
      {pagination && (
        <TablePagination
          rowsPerPageOptions={Object.values(ROWS_PER_PAGE_OPTIONS)}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default StickyHeaderTable;
