import React, { useEffect } from "react";

import {
  Grid,
  Paper,
  CardContent,
  Typography,
  Card,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  CardHeader,
} from "@mui/material";
import CountUp from "react-countup";
import { Heatmap } from "@mui/x-charts-pro/Heatmap";
import { HeatmapValueType } from "@mui/x-charts-pro/models";
import { interpolateBlues } from "d3-scale-chromatic";
// import { LineChart, BarChart } from "@mui/x-charts";
import { useDashboardViewModel } from "@app/features/Dashboard/view/dashboardViewModel";
import { useDashboardRepository } from "@app/features/Dashboard/data/dashboardRepository";
import dashboardService from "@app/services/dashboard";
import EmptyState from "@app/components/organisms/EmptyState";

import { lotties } from "@app/assets";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/store";
import LoadingLock from "@app/components/molecules/LoadingLock";
import theme from "@app/config/theme";
// const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
// const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
// const xLabels = [
//   "Page A",
//   "Page B",
//   "Page C",
//   "Page D",
//   "Page E",
//   "Page F",
//   "Page G",
// ];

const dataset = [
  {
    london: 59,
    paris: 57,
    newYork: 86,
    seoul: 21,
    month: "January",
  },
  {
    london: 50,
    paris: 52,
    newYork: 78,
    seoul: 28,
    month: "February",
  },
  {
    london: 47,
    paris: 53,
    newYork: 106,
    seoul: 41,
    month: "March",
  },
  {
    london: 54,
    paris: 56,
    newYork: 92,
    seoul: 73,
    month: "April",
  },
  {
    london: 57,
    paris: 69,
    newYork: 92,
    seoul: 99,
    month: "May",
  },
  {
    london: 60,
    paris: 63,
    newYork: 103,
    seoul: 144,
    month: "June",
  },
  {
    london: 59,
    paris: 60,
    newYork: 105,
    seoul: 319,
    month: "July",
  },
  {
    london: 65,
    paris: 60,
    newYork: 106,
    seoul: 249,
    month: "August",
  },
  {
    london: 51,
    paris: 51,
    newYork: 95,
    seoul: 131,
    month: "September",
  },
  {
    london: 60,
    paris: 65,
    newYork: 97,
    seoul: 55,
    month: "October",
  },
  {
    london: 67,
    paris: 64,
    newYork: 76,
    seoul: 48,
    month: "November",
  },
  {
    london: 61,
    paris: 70,
    newYork: 103,
    seoul: 25,
    month: "December",
  },
];

const data = dataset.flatMap(
  ({ london, paris, newYork, seoul }, monthIndex): HeatmapValueType[] => [
    [0, monthIndex, london],
    [1, monthIndex, paris],
    [2, monthIndex, newYork],
    [3, monthIndex, seoul],
  ]
);

const xData = ["London", "Paris", "NewYork", "Seoul"];
const yData = dataset.flatMap(({ month }) => month);

const Dashboard = () => {
  const dashboardRepository = useDashboardRepository(dashboardService);
  const { getDashboard, dashboardData, isLoading } =
    useDashboardViewModel(dashboardRepository);

  const { currentClasse } = useSelector((state: RootState) => state.classe);

  useEffect(() => {
    if (currentClasse) {
      getDashboard(currentClasse.id);
    }
  }, [currentClasse, getDashboard]);

  return (
    <Paper sx={{ p: 2 }}>
      <LoadingLock isLoading={isLoading} />
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Novos alunos no mês
              </Typography>
              <Typography variant="h5" component="div">
                <CountUp
                  prefix="+"
                  end={dashboardData?.newUserInMonth || 0}
                  suffix=" alunos"
                />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Total de alunos
              </Typography>
              <Typography variant="h5" component="div">
                <CountUp
                  end={dashboardData?.studentsCount || 0}
                  suffix=" alunos"
                />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Acessos à turma no mês
              </Typography>
              <Typography variant="h5" component="div">
                <CountUp
                  prefix="+"
                  end={dashboardData?.classeAccessCount || 0}
                  suffix=" alunos"
                />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ height: 400 }}>
            <CardHeader
              avatar={
                <Avatar
                  sx={{ bgcolor: theme.palette.primary.main }}
                  aria-label="recipe"
                >
                  🥳
                </Avatar>
              }
              title="Aniversáriantes do Mês"
              subheader={new Date().getUTCMonth()}
            />
            <Divider />
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                height: 320,
                "& ul": { padding: 0 },
              }}
              subheader={<li />}
            >
              {[0, 1, 2, 3, 4].map((sectionId) => (
                <li key={`section-${sectionId}`}>
                  <ul>
                    <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                    {[0, 1, 2].map((item) => (
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            alt="Remy Sharp"
                            src="/static/images/avatar/1.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary="Brunch this weekend?"
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{
                                  color: "text.primary",
                                  display: "inline",
                                }}
                              >
                                Ali Connors
                              </Typography>
                              {
                                " — I'll be in your neighborhood doing errands this…"
                              }
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ))}
                  </ul>
                </li>
              ))}
            </List>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Card>
            <Heatmap
              height={400}
              xAxis={[{ data: xData }]}
              yAxis={[{ data: yData }]}
              series={[{ data }]}
              zAxis={[
                {
                  min: 20,
                  max: 300,
                  colorMap: {
                    type: "continuous",
                    color: interpolateBlues,
                  },
                },
              ]}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={12}>
          <EmptyState
            lottieAnimation={lotties.dashboard}
            message="Estamos finalizando a contrução do nosso dashboard, fica atento em breve você poderá ver mais informações valiosas por aqui💜."
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Word of the Day
              </Typography>
              <Typography variant="h5" component="div">
                tste
              </Typography>
              <LineChart
                height={300}
                series={[
                  { data: pData, label: "pv" },
                  { data: uData, label: "uv" },
                ]}
                xAxis={[{ scaleType: "point", data: xLabels }]}
              />
            </CardContent>
          </Card>
        </Grid> */}
        {/* <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Word of the Day
              </Typography>
              <Typography variant="h5" component="div">
                tste
              </Typography>
              <BarChart
                height={300}
                xAxis={[
                  {
                    scaleType: "band",
                    data: ["group A", "group B", "group C"],
                  },
                ]}
                series={[{ data: [4, 3, 5] }]}
              />
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
    </Paper>
  );
};

export default Dashboard;
