import { useCallback } from "react";

import { ICollectionRepository } from "@app/features/Collection/data/collectionRepository";

import listCollectionsUseCase from "@app/features/Collection/domain/use-cases/listCollectionsUseCase";
import deleteCollectionUseCase from "@app/features/Collection/domain/use-cases/deleteCollectionUseCase";

import { IPageModel } from "@app/constants/interfaces";

import handleApplicationError from "@app/handlers/handleApiError";
import { TListTagsSearchParams } from "@app/services/tag";
import showNotification from "@app/components/molecules/Toast";

const useListCollectionsViewModel = (repository: ICollectionRepository) => {
  const listCollections = useCallback(
    async (
      companyId: number,
      pageConfig?: IPageModel,
      filterParams?: TListTagsSearchParams
    ) => {
      try {
        await listCollectionsUseCase(
          {
            listCollections: repository.listCollections,
          },
          companyId,
          pageConfig,
          filterParams
        );
      } catch (error) {
        handleApplicationError.handleError(error);
      }
    },
    [repository.listCollections]
  );

  const removeCollection = useCallback(
    async (collectionId: number, companyId: number, pageConfig: IPageModel) => {
      try {
        await deleteCollectionUseCase(
          {
            deleteCollection: repository.deleteCollection,
          },
          collectionId
        );
        listCollections(companyId, pageConfig);
        showNotification("Coleção removida com sucesso", "SUCCESS");
      } catch (error) {
        handleApplicationError.handleError(error);
      }
    },
    [repository.deleteCollection, listCollections]
  );

  return { listCollections, removeCollection };
};

export { useListCollectionsViewModel };
