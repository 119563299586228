import ICollectionModel from "@app/features/Collection/domain/models/ICollectionModel";

export const SET_COLLECTIONS = "SET_COLLECTIONS";
export const LOADING_COLLECTIONS = "LOADING_COLLECTIONS";

export interface ICollectionStore {
  collections: ICollectionModel[];
  loading: boolean;
  currentCollection?: ICollectionModel;
}

interface AllCollectionsAction {
  type: typeof SET_COLLECTIONS;
  payload: ICollectionModel[];
}

interface LoadingAllCollectionsAction {
  type: typeof LOADING_COLLECTIONS;
  payload: boolean;
}

export type CollectionActionTypes =
  | AllCollectionsAction
  | LoadingAllCollectionsAction;
