import { useCallback, useState } from "react";

import createTagUseCase from "@app/features/Tag/domain/use-cases/createTagUseCase";

import { ITagRepository } from "@app/features/Tag/data/tagRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";
import { useNavigate } from "react-router-dom";

export type TCreateTagViewModel = {
  name: string;
  color: string;
  companyId: number;
  languageId: number;
};

const useCreateTagViewModel = (repository: ITagRepository) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const createTag = useCallback(
    async (data: TCreateTagViewModel, callback?: () => void) => {
      try {
        setLoading(true);

        await createTagUseCase(
          {
            createTag: repository.createTag,
          },
          data
        );
        setLoading(false);
        showNotification("Marcador criado com sucesso", "SUCCESS");
        if (callback) callback();
        else navigate(-1);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.createTag, navigate]
  );

  return { isLoading, createTag };
};

export { useCreateTagViewModel };
