import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { useTagRepository } from "@app/features/Tag/data/tagRepository";
import { useCreateTagViewModel } from "@app/features/Tag/views/Create/createTagViewModel";
import tagService from "@app/services/tag";

import StandardTemplate from "@app/components/templates/Standard";
import LoadingLock from "@app/components/molecules/LoadingLock";
import FooterActions from "@app/components/molecules/FooterActions";

import TagForm, { TTagForm } from "@app/features/Tag/views/Form";

import { RootState } from "@app/config/store";
import { useNavigate } from "react-router-dom";

type TCreateTagProps = {
  onFinish?: () => void;
};

const CreateTag = ({ onFinish }: TCreateTagProps) => {
  const navigate = useNavigate();
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const tagRepository = useTagRepository(tagService);
  const { createTag, isLoading } = useCreateTagViewModel(tagRepository);

  const handleSave = useCallback(
    (data: TTagForm) => {
      if (currentCompany) {
        createTag({ companyId: currentCompany.id, ...data }, onFinish);
      }
    },
    [currentCompany, createTag, onFinish]
  );

  const handleCancel = useCallback(() => {
    if (onFinish) onFinish();
    else navigate(-1);
  }, [navigate, onFinish]);

  return (
    <StandardTemplate title="Novo marcador" showBackButton={!onFinish}>
      <LoadingLock isLoading={isLoading} />
      <TagForm
        onValidateSuccess={handleSave}
        footerActions={<FooterActions onCancel={handleCancel} />}
      />
    </StandardTemplate>
  );
};

export default CreateTag;
