import { IModulePageRepository } from "@app/features/ModulePage/data/modulePageRepository";
import { TEditLessonFromModuleViewModel } from "@app/features/Module/view/List/listModuleViewModel";

const editLessonFromModuleUseCase = async (
  repository: Pick<IModulePageRepository, "editLessonDetailFromModule">,
  data: TEditLessonFromModuleViewModel
) => {
  await repository.editLessonDetailFromModule({
    ...data,
    pageIds: data.lessonIds,
  });
};

export default editLessonFromModuleUseCase;
