import {
  combineReducers,
  CombinedState,
  createStore,
  applyMiddleware,
  Action,
  Dispatch,
} from "redux";
import thunk from "redux-thunk";

import companyReducer from "@app/features/Company/data/companyReducer";
import classeReducer from "@app/features/Classe/data/classeReducer";
import profileReducer from "@app/features/Profile/data/profileReducer";
import languageReducer from "@app/features/Language/data/languageReducer";
import collectionReducer from "@app/features/Collection/data/collectionReducer";

import { IClasseStore } from "@app/features/Classe/data/classeActionsType";
import { ICompanyStore } from "@app/features/Company/data/companyActionsType";
import { IProfileStore } from "@app/features/Profile/data/profileActionsType";
import { ILanguageStore } from "@app/features/Language/data/languageActionsType";
import { ICollectionStore } from "@app/features/Collection/data/collectionActionsType";

export type RootState = {
  classe: IClasseStore;
  company: ICompanyStore;
  profile: IProfileStore;
  language: ILanguageStore;
  collection: ICollectionStore;
};

const appReducer = combineReducers<RootState>({
  classe: classeReducer,
  company: companyReducer,
  profile: profileReducer,
  language: languageReducer,
  collection: collectionReducer,
});

const rootReducer = (
  state: RootState | undefined,
  action: Action
): CombinedState<RootState> => {
  if (action.type === "RESET_STATE") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = createStore(rootReducer, {}, applyMiddleware(thunk));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchStore = store.dispatch as Dispatch<any>;

export default rootReducer;
