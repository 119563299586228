import { useCallback } from "react";

import { TEditLessonDetailFromModuleRemoteData } from "@app/services/module-page/remote-types/TEditLessonDetailFromModuleRemoteData";
import IModulePageEntity from "@app/features/ModulePage/data/IModulePageEntity";

import { IModulePageService } from "@app/services/module-page";

export interface IModulePageRepository {
  removeLessonFromModule(moduleId: number, lessonId: number): Promise<void>;
  editLessonDetailFromModule(
    data: TEditLessonDetailFromModuleRemoteData
  ): Promise<void>;
  getLessonDetailFromModule(
    moduleId: number,
    lessonId: number
  ): Promise<IModulePageEntity>;
}

const useModulePageRepository = (
  modulePageService: IModulePageService
): IModulePageRepository => {
  const removeLessonFromModule = useCallback(
    async (moduleId: number, lessonId: number): Promise<void> => {
      await modulePageService.deleteLessonFromModuleService(moduleId, lessonId);
    },
    [modulePageService]
  );
  const editLessonDetailFromModule = useCallback(
    async (data: TEditLessonDetailFromModuleRemoteData): Promise<void> => {
      await modulePageService.editLessonDetailFromModuleService(data);
    },
    [modulePageService]
  );

  const getLessonDetailFromModule = useCallback(
    async (moduleId: number, lessonId: number): Promise<IModulePageEntity> => {
      const data = await modulePageService.getLessonDetailFromModuleService(
        moduleId,
        lessonId
      );
      return data;
    },
    [modulePageService]
  );

  return {
    removeLessonFromModule,
    getLessonDetailFromModule,
    editLessonDetailFromModule,
  };
};

export { useModulePageRepository };
