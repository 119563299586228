import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";

import editTagUseCase from "@app/features/Tag/domain/use-cases/editTagUseCase";
import getTagUseCase from "@app/features/Tag/domain/use-cases/getTagUseCase";

import ITagModel from "@app/features/Tag/domain/models/ITagModel";

import { ITagRepository } from "@app/features/Tag/data/tagRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";

export type TEditTagViewModel = {
  id: number;
  name?: string;
  color?: string;
  laguanageId?: number;
  companyId: number;
};

const useEditTagViewModel = (repository: ITagRepository) => {
  const [isLoading, setLoading] = useState(false);
  const [tag, setTag] = useState<ITagModel>();
  const navigate = useNavigate();

  const editTag = useCallback(
    async (data: TEditTagViewModel) => {
      try {
        setLoading(true);

        await editTagUseCase(
          {
            editTag: repository.editTag,
          },
          data
        );
        setLoading(false);
        showNotification("Marcador alterado com sucesso", "SUCCESS");
        navigate(-1);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.editTag, navigate]
  );

  const getTag = useCallback(
    async (tagId: number) => {
      try {
        setLoading(true);

        const data = await getTagUseCase(
          {
            getTag: repository.getTag,
          },
          tagId
        );
        setTag(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.getTag]
  );

  return { isLoading, editTag, getTag, tag };
};

export { useEditTagViewModel };
