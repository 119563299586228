import { Action, Dispatch } from "redux";

import ICollectionModel from "@app/features/Collection/domain/models/ICollectionModel";

import {
  SET_COLLECTIONS,
  LOADING_COLLECTIONS,
} from "@app/features/Collection/data/collectionActionsType";

export const setCollectionsAction =
  (collections?: ICollectionModel[]) => (dispatch: Dispatch<Action>) => {
    dispatch({ type: SET_COLLECTIONS, payload: collections });
  };

export const setLoadingCollectionsAction =
  (value: boolean) =>
  (dispatch: Dispatch<Action>): Action =>
    dispatch({ type: LOADING_COLLECTIONS, payload: value });
