import { useCallback, useState } from "react";

import { ICollectionRepository } from "@app/features/Collection/data/collectionRepository";

import listLessonsFromCollectionUseCase from "@app/features/Collection/domain/use-cases/listLessonsFromCollectionUseCase";
import removeLessonUseCase from "@app/features/Lesson/domain/use-cases/removeLessonUseCase";

import IPaginationModel, { IPageModel } from "@app/constants/interfaces";

import handleApplicationError from "@app/handlers/handleApiError";
import { TListTagsSearchParams } from "@app/services/tag";
import showNotification from "@app/components/molecules/Toast";
import ILessonModel from "@app/features/Lesson/domain/models/ILessonModel";
import { ILessonRepository } from "@app/features/Lesson/data/lessonRepository";

const useListLessonsViewModel = (
  collectionRepository: ICollectionRepository,
  lessonRepository: ILessonRepository
) => {
  const [lessons, setLessons] = useState<IPaginationModel<ILessonModel>>({
    count: 0,
    data: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const listLessons = useCallback(
    async (
      collectionId: number,
      pageConfig: IPageModel,
      filterParams?: TListTagsSearchParams
    ) => {
      try {
        setIsLoading(true);
        const data = await listLessonsFromCollectionUseCase(
          {
            listLessonsFromCollection:
              collectionRepository.listLessonsFromCollection,
          },
          collectionId,
          pageConfig,
          filterParams
        );
        setLessons(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(true);
        handleApplicationError.handleError(error);
      }
    },
    [collectionRepository.listLessonsFromCollection]
  );

  const removeLesson = useCallback(
    async (lessonId: number, companyId: number, pageConfig: IPageModel) => {
      try {
        await removeLessonUseCase(
          {
            removeLesson: lessonRepository.removeLesson,
          },
          lessonId
        );
        listLessons(companyId, pageConfig);
        showNotification("Aula removida com sucesso", "SUCCESS");
      } catch (error) {
        handleApplicationError.handleError(error);
      }
    },
    [lessonRepository.removeLesson, listLessons]
  );

  return { listLessons, removeLesson, isLoading, lessons };
};

export { useListLessonsViewModel };
