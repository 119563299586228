import { ICollectionRepository } from "@app/features/Collection/data/collectionRepository";

import ICollectionModel from "@app/features/Collection/domain/models/ICollectionModel";

import ICollectionEntity from "@app/features/Collection/data/ICollectionEntity";

const getCollectionUseCase = async (
  repository: Pick<ICollectionRepository, "getCollection">,
  collectionId: number
): Promise<ICollectionModel> => {
  const collectionEntity = await repository.getCollection(collectionId);
  const domain = mapToDomain(collectionEntity);

  return domain;
};

const mapToDomain = (tagEntity: ICollectionEntity): ICollectionModel => {
  const { id, name, language, companyId } = tagEntity;

  return {
    id,
    name,
    language,
    companyId,
  };
};

export default getCollectionUseCase;
