import { useCallback, useState } from "react";

import listModulesUseCase from "@app/features/Module/domain/use-cases/listModulesUseCase";
import editModuleUseCase from "@app/features/Module/domain/use-cases/editModuleUseCase";
import deleteModuleService from "@app/features/Module/domain/use-cases/deleteModuleService";
import getLessonsFromModuleUseCase from "@app/features/Module/domain/use-cases/getLessonsFromModuleUseCase";
import addLessonToModuleUseCase from "@app/features/Module/domain/use-cases/addLessonToModuleUseCase";

import removeLessonFromModuleUseCase from "@app/features/ModulePage/domain/use-cases/removeLessonFromModuleUseCase";
import getLessonDetailFromModuleUseCase from "@app/features/ModulePage/domain/use-cases/getLessonDetailFromModuleUseCase";
import editLessonFromModuleUseCase from "@app/features/ModulePage/domain/use-cases/editLessonFromModuleUseCase";

import { IModuleRepository } from "@app/features/Module/data/moduleRepository";
import { IModulePageRepository } from "@app/features/ModulePage/data/modulePageRepository";

import IModuleModel from "@app/features/Module/domain/models/IModuleModel";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";
import { RELEASE_DATE_TYPE } from "@app/constants/enums";
import IModulePageModel from "@app/features/ModulePage/domain/models/IModulePageModel";

export type TEditModuleViewModel = {
  id: number;
  name?: string;
  reoder?: {
    source: number;
    destination: number;
  };
};

export type TAddLessonToModuleViewModel = {
  moduleId: number;
  lessonIds: number[];
  notify?: boolean;
  releaseDateType: RELEASE_DATE_TYPE;
  releaseAfterDay?: number;
  releaseDate?: string;
};

export type TEditLessonFromModuleViewModel = {
  moduleId: number;
  lessonIds: number[];
  releaseDateType: RELEASE_DATE_TYPE;
  releaseAfterDay?: number;
  releaseDate?: string;
  reoder?: {
    source: number;
    destination: number;
  };
};

const useListModuleViewModel = (
  moduleRepository: IModuleRepository,
  modulePageRepository: IModulePageRepository
) => {
  const [isLoading, setLoading] = useState(false);
  const [modules, setModules] = useState<IModuleModel[]>([]);

  const [lessonSettings, setLessonSettings] = useState<IModulePageModel>();

  const getModules = useCallback(
    async (classeId: number) => {
      try {
        setLoading(true);

        const data = await listModulesUseCase(
          {
            getModules: moduleRepository.getModules,
          },
          classeId
        );
        setLoading(false);
        setModules(data);
      } catch (error) {
        handleApplicationError.handleError(error);
      }
    },
    [moduleRepository.getModules]
  );

  const getLessonsFromModule = useCallback(
    async (moduleId: number) => {
      try {
        setLoading(true);

        const data = await getLessonsFromModuleUseCase(
          {
            getLessonsFromModule: moduleRepository.getLessonsFromModule,
          },
          moduleId
        );
        setLoading(false);
        setModules((currentModules) => {
          let moduleIndex: number = -1;

          const module = currentModules.find((module, index) => {
            if (module.id === moduleId) {
              moduleIndex = index;
              return module;
            }
            return null;
          });

          if (module) {
            module.lessons = data;
            currentModules[moduleIndex] = module;
          }

          return [...currentModules];
        });
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [moduleRepository.getLessonsFromModule]
  );

  const editModule = useCallback(
    async (data: TEditModuleViewModel, successCallback?: () => void) => {
      try {
        setLoading(true);

        await editModuleUseCase(
          {
            editModule: moduleRepository.editModule,
          },
          data
        );
        setLoading(false);
        showNotification("Módulo alterado com sucesso", "SUCCESS");
        if (successCallback) successCallback();
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [moduleRepository.editModule]
  );

  const deleteModule = useCallback(
    async (data: TEditModuleViewModel, successCallback?: () => void) => {
      try {
        setLoading(true);

        await deleteModuleService(
          {
            deleteModule: moduleRepository.deleteModule,
          },
          data
        );

        setModules((currentModules) => {
          const modules = currentModules.filter(
            (module) => module.id !== data.id
          );

          return [...modules];
        });
        setLoading(false);
        showNotification("Módulo removido com sucesso", "SUCCESS");
        if (successCallback) successCallback();
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [moduleRepository.deleteModule]
  );

  const addLessonToModule = useCallback(
    async (data: TAddLessonToModuleViewModel) => {
      try {
        setLoading(true);
        await addLessonToModuleUseCase(
          {
            addLessonToModule: moduleRepository.addLessonToModule,
          },
          data
        );
        showNotification("Aulas adicionadas com sucesso.", "SUCCESS");
        getLessonsFromModule(data.moduleId);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [moduleRepository.addLessonToModule, getLessonsFromModule]
  );

  const removeLessonFromModule = useCallback(
    async (moduleId: number, lessonId: number) => {
      try {
        setLoading(true);

        await removeLessonFromModuleUseCase(
          {
            removeLessonFromModule: modulePageRepository.removeLessonFromModule,
          },
          moduleId,
          lessonId
        );
        getLessonsFromModule(moduleId);
        setLoading(false);
        showNotification("Aula removida com sucesso", "SUCCESS");
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [modulePageRepository.removeLessonFromModule, getLessonsFromModule]
  );

  const getLessonDetailFromModule = useCallback(
    async (moduleId: number, lessonId: number) => {
      try {
        setLoading(true);

        const data = await getLessonDetailFromModuleUseCase(
          {
            getLessonDetailFromModule:
              modulePageRepository.getLessonDetailFromModule,
          },
          moduleId,
          lessonId
        );
        setLessonSettings(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [modulePageRepository.getLessonDetailFromModule]
  );

  const editLessonFromModule = useCallback(
    async (data: TEditLessonFromModuleViewModel) => {
      try {
        setLoading(true);

        await editLessonFromModuleUseCase(
          {
            editLessonDetailFromModule:
              modulePageRepository.editLessonDetailFromModule,
          },
          data
        );
        setLoading(false);
        showNotification("Aula alterada com sucesso", "SUCCESS");
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [modulePageRepository.editLessonDetailFromModule]
  );

  return {
    isLoading,
    modules,
    lessonSettings,
    getModules,
    setModules,
    editModule,
    deleteModule,
    removeLessonFromModule,
    getLessonsFromModule,
    addLessonToModule,
    getLessonDetailFromModule,
    editLessonFromModule,
  };
};

export { useListModuleViewModel };
