import {
  CollectionActionTypes,
  ICollectionStore,
} from "./collectionActionsType";

const INITIAL_STATE: ICollectionStore = {
  collections: [],
  loading: true,
};

const collectionReducer = (
  state = INITIAL_STATE,
  action: CollectionActionTypes
): ICollectionStore => {
  switch (action.type) {
    case "SET_COLLECTIONS":
      return { ...state, collections: action.payload, loading: false };

    case "LOADING_COLLECTIONS":
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};

export default collectionReducer;
