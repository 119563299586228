import { useCallback, useState } from "react";

import listResourcesUseCase from "@app/features/Resource/domain/use-cases/listResourcesUseCase";
import deleteResourceUseCase from "@app/features/Resource/domain/use-cases/deleteResourceUseCase";

import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";
import { ITagRepository } from "@app/features/Tag/data/tagRepository";

import listTagsUseCase from "@app/features/Tag/domain/use-cases/listTagsUseCase";

import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";
import ITagModel from "@app/features/Tag/domain/models/ITagModel";

import handleApplicationError from "@app/handlers/handleApiError";
import { INVITE_STATUS } from "@app/constants/enums";
import IPaginationModel, { IPageModel } from "@app/constants/interfaces";
import { TListResourcesSearchParams } from "@app/services/resource";
import showNotification from "@app/components/molecules/Toast";

export type TInviteClasseViewModel = {
  classeId: number;
  inviteStatus: INVITE_STATUS;
};

const useListResourcesViewModel = (
  resourceRepository: IResourceRepository,
  tagRepository: ITagRepository
) => {
  const [resources, setResources] = useState<IPaginationModel<IResourceModel>>({
    count: 0,
    data: [],
  });
  const [tags, setTags] = useState<ITagModel[]>([]);
  const [isLoading, setLoading] = useState(false);

  const listResources = useCallback(
    async (
      companyId: number,
      pageConfig: IPageModel,
      filterParams?: TListResourcesSearchParams
    ) => {
      try {
        setLoading(true);
        const data = await listResourcesUseCase(
          {
            listResources: resourceRepository.listResources,
          },
          companyId,
          pageConfig,
          filterParams
        );
        setResources(data);
        setLoading(false);
      } catch (error) {
        handleApplicationError.handleError(error);
      }
    },
    [resourceRepository.listResources]
  );

  const deleteResource = useCallback(
    async (resourceId: number, companyId: number, pageConfig: IPageModel) => {
      try {
        setLoading(true);
        await deleteResourceUseCase(
          {
            deleteResource: resourceRepository.deleteResource,
          },
          resourceId
        );
        showNotification("Recurso removido com sucesso", "SUCCESS");
        listResources(companyId, pageConfig);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [resourceRepository.deleteResource, listResources]
  );

  const listTags = useCallback(
    async (companyId: number) => {
      try {
        setLoading(true);

        const result = await listTagsUseCase(
          {
            listTags: tagRepository.listTags,
          },
          companyId
        );
        setTags(result as ITagModel[]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [tagRepository.listTags]
  );

  return {
    isLoading,
    resources,
    listResources,
    deleteResource,
    listTags,
    tags,
  };
};

export { useListResourcesViewModel };
