import React, { ReactNode, useCallback } from "react";

import { FiPlus, FiArrowLeft, FiArrowDown } from "react-icons/fi";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { Regular, Headline } from "@app/components/atoms/Text";

import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { FilterAlt } from "@mui/icons-material";
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";

type StandardHeaderButton = {
  textButton?: string;
  iconButton?: ReactNode;
  onClickButton?: () => void;
  isVisible?: boolean;
};

export type StandardHeaderProps = {
  title?: string;
  helperText?: string;
  primaryButton?: StandardHeaderButton;
  secondaryButton?: StandardHeaderButton;
  buttonOptions?: StandardHeaderButton[];
  onBackButton?: () => void;
  showBackButton?: boolean;
};

const StandardHeader = ({
  title,
  helperText,
  primaryButton,
  secondaryButton,
  buttonOptions,
  onBackButton,
  showBackButton,
}: StandardHeaderProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleBack = useCallback(() => {
    if (onBackButton) {
      onBackButton();
    } else {
      navigate(-1);
    }
  }, [navigate, onBackButton]);

  const classes = useStyles();

  return (
    <div style={classes.headerCreate}>
      {showBackButton && (
        <Button
          color="primary"
          variant="text"
          size="small"
          onClick={handleBack}
          startIcon={<FiArrowLeft />}
        >
          Voltar
        </Button>
      )}
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={6} sm={6}>
          <Headline>{title}</Headline>
          {helperText && <Regular>{helperText}</Regular>}
        </Grid>
        <Grid
          container
          item
          xs={6}
          sm={6}
          direction="row"
          justifyContent="flex-end"
        >
          {secondaryButton?.isVisible && (
            <Grid item sx={{ mr: 1 }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={secondaryButton.onClickButton}
                startIcon={secondaryButton.iconButton}
              >
                {secondaryButton.textButton}
              </Button>
            </Grid>
          )}

          {primaryButton?.isVisible && (
            <Grid item sx={{ mr: 1 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={primaryButton.onClickButton}
                startIcon={primaryButton.iconButton}
              >
                {primaryButton.textButton}
              </Button>
            </Grid>
          )}
          {!!buttonOptions?.length && (
            <Grid item>
              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
              >
                <Button
                  onClick={buttonOptions[0].onClickButton}
                  startIcon={buttonOptions[0].iconButton}
                >
                  {buttonOptions[0].textButton}
                </Button>
                {buttonOptions.length > 1 && (
                  <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <FiArrowDown />
                  </Button>
                )}
              </ButtonGroup>
              <Popper
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {buttonOptions.slice(1).map((option, index) => (
                            <MenuItem
                              key={index}
                              onClick={option.onClickButton}
                            >
                              {option.textButton}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

StandardHeader.defaultProps = {
  title: "",
  helperText: "",
  primaryButton: {
    textButton: "Novo",
    iconButton: <FiPlus />,
    onClickButton: null,
    isVisible: true,
  },
  secondaryButton: {
    textButton: "Filtro",
    iconButton: <FilterAlt />,
    onClickButton: null,
    isVisible: true,
  },
  showBackButton: false,
};

export default StandardHeader;
