import React, { ReactNode, useCallback, useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Paper, TextField, Grid } from "@mui/material";
import { Box } from "@mui/system";

export type TModuleForm = {
  name: string;
};

type ModuleFormProps = {
  onValidateSuccess: (data: TModuleForm) => void;
  footerActions: ReactNode;
  dataForm?: TModuleForm;
  edit?: boolean;
};

const ModuleForm = ({
  onValidateSuccess,
  footerActions,
  dataForm,
  edit,
}: ModuleFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Preencha o campo nome"),
      })
    ),
    defaultValues: {
      name: "",
    },
    mode: "onSubmit",
  });

  const onSubmit = useCallback(
    (data: TModuleForm) => {
      onValidateSuccess(data);
    },
    [onValidateSuccess]
  );

  useEffect(() => {
    reset(dataForm);
  }, [dataForm, reset]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={12}>
            <TextField
              id="name"
              type="text"
              fullWidth
              margin="normal"
              variant="outlined"
              label="Nome"
              InputLabelProps={{ shrink: true }}
              error={!!errors.name}
              helperText={!!errors.name && errors.name.message}
              {...register("name")}
            />
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ mt: 10 }}>{footerActions}</Box>
    </form>
  );
};

export default ModuleForm;
