import { IModulePageRepository } from "@app/features/ModulePage/data/modulePageRepository";

const removeLessonFromModuleUseCase = async (
  repository: Pick<IModulePageRepository, "removeLessonFromModule">,
  moduleId: number,
  lessonId: number
) => {
  await repository.removeLessonFromModule(moduleId, lessonId);
};

export default removeLessonFromModuleUseCase;
