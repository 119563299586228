import React, { useCallback, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Chip, Stack, Typography } from "@mui/material";

import { useClasseRepository } from "@app/features/Classe/data/classeRespository";
import { useListUsersViewModel } from "@app/features/User/view/List/listUsersViewModel";
import classeService, {
  TListUsersFromClasseSearchParams,
} from "@app/services/classe";
import IUserProfileModel from "@app/features/User/domain/models/IUserModel";

import LoadingLock from "@app/components/molecules/LoadingLock";
import DrawerFilter, {
  IDrawerFilterRef,
  TFilterPayload,
} from "@app/components/organisms/DrawerFilter";
import StickyHeaderTable from "@app/components/organisms/StickyHeaderTable";
import StandardTemplate from "@app/components/templates/Standard";

import { IPageModel } from "@app/constants/interfaces";
import { ROWS_PER_PAGE_OPTIONS } from "@app/constants/optionsSelect";

import { RootState } from "@app/config/store";
import { ROLE, USER_CLASSE_STATUS } from "@app/constants/enums";
import useRestricted from "@app/hooks/useRestricted";
import { FilterAlt } from "@mui/icons-material";
import { FiPlus } from "react-icons/fi";

const ListUsers = () => {
  const { isAllowedTo } = useRestricted();
  const [paginationConfig, setPaginationConfig] = useState<IPageModel>({
    page: 0,
    amount: ROWS_PER_PAGE_OPTIONS.MIN,
  });
  const [filters, setFilters] = useState<TFilterPayload[]>([]);

  const { currentClasse } = useSelector((state: RootState) => state.classe);

  const navigate = useNavigate();

  const classeRepository = useClasseRepository(classeService);
  const { isLoading, users, listUserFromClasse, removeUserFromClasse } =
    useListUsersViewModel(classeRepository);

  const draweFilterRef = useRef<IDrawerFilterRef>();

  const handleCreateButton = () => {
    navigate("/admin/users/create");
  };

  const handleSetFilter = (values: TFilterPayload[]) => {
    setFilters([...values]);
  };

  const handleGetNewPage = (newPage: number, amount: number) => {
    setPaginationConfig({ amount, page: newPage });
    if (currentClasse) {
      listUserFromClasse(currentClasse.id, paginationConfig);
    }
  };

  const handleEdit = (data: IUserProfileModel) => {
    navigate(`/admin/users/edit/${data.id}`);
  };

  const removeFilter = (filter: TFilterPayload) => {
    let newFilters = filters.filter((f) => f.id !== filter.id);
    setFilters([...newFilters]);
  };

  const handleDelete = useCallback(
    (data: IUserProfileModel) => {
      if (currentClasse) {
        removeUserFromClasse(currentClasse.id, data.id, paginationConfig);
      }
    },
    [currentClasse, paginationConfig, removeUserFromClasse]
  );

  const getColorStatus = useCallback(
    (data: USER_CLASSE_STATUS): "info" | "success" | "warning" => {
      switch (data) {
        case USER_CLASSE_STATUS.ACTIVE:
          return "success";
        case USER_CLASSE_STATUS.INACTIVE:
          return "warning";
        default:
          return "info";
      }
    },
    []
  );

  useEffect(() => {
    let filtersData: TListUsersFromClasseSearchParams = {};
    filters.forEach((f) => {
      if (f.value) {
        filtersData = { [f.id]: f.value };
      }
    });
    if (currentClasse) {
      listUserFromClasse(currentClasse.id, paginationConfig, filtersData);
    }
  }, [listUserFromClasse, currentClasse, filters, paginationConfig]);

  return (
    <>
      <LoadingLock isLoading={isLoading} />

      <StandardTemplate
        title="Colaboradores"
        secondaryButton={{
          isVisible: true,
          textButton: "Filtro",
          iconButton: <FilterAlt />,
          onClickButton: draweFilterRef.current?.openDrawer,
        }}
        primaryButton={{
          iconButton: <FiPlus />,
          textButton: "Novo",
          isVisible: isAllowedTo([ROLE.OWNER, ROLE.ADMIN]),
          onClickButton: handleCreateButton,
        }}
      >
        {!!filters.length && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ mb: 3 }}
          >
            {filters.map((filter) => (
              <Chip
                variant="outlined"
                label={`${filter.labelFilter}: ${filter.selectValueLabel}`}
                onDelete={() => removeFilter(filter)}
              />
            ))}
          </Stack>
        )}
        <StickyHeaderTable
          identifierColumnKey="id"
          pagination
          dialogMessage={{
            title: "Remover usuário?",
            description:
              "Tem certeza que deseja remover este usuário? Todos os dados vinculados a ele serão removidos e ele perderá acesso a a turma.",
          }}
          count={users.count}
          onPageChange={handleGetNewPage}
          onEdit={handleEdit}
          onDelete={handleDelete}
          columns={[
            {
              id: "photo",
              label: "Avatar",
              component: "AVATAR",
            },
            {
              id: "name",
              label: "Nome",
              align: "left",
            },
            {
              id: "email",
              label: "E-mail",
              align: "left",
            },
            {
              id: "userStatusClasse",
              label: "Status da conta",
              align: "center",
              component: "CUSTOM",
              customComponent: (data) => (
                <Chip
                  label={data.userStatusClasse}
                  color={getColorStatus(data.userStatusClasse)}
                />
              ),
            },
            {
              id: "role",
              label: "Permissão",
              align: "center",
              component: "CUSTOM",
              customComponent: (data) => (
                <Typography variant="overline" display="block" align="center">
                  {data.role.name}
                </Typography>
              ),
            },
          ]}
          rows={users.data}
        />
      </StandardTemplate>
      <DrawerFilter
        ref={draweFilterRef}
        onFilter={handleSetFilter}
        fields={[
          {
            id: "name",
            label: "Nome",
            type: "TEXT",
          },
          {
            id: "email",
            label: "Email",
            type: "TEXT",
          },
        ]}
      />
    </>
  );
};

export default ListUsers;
