import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import editCollectionUseCase from "@app/features/Collection/domain/use-cases/editCollectionUseCase";
import getCollectionUseCase from "@app/features/Collection/domain/use-cases/getCollectionUseCase";
import ICollectionModel from "@app/features/Collection/domain/models/ICollectionModel";

import { ICollectionRepository } from "@app/features/Collection/data/collectionRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";

export type TEditCollectionViewModel = {
  id: number;
  name: string;
  languageId: number;
  companyId: number;
};

const useEditCollectionViewModel = (repository: ICollectionRepository) => {
  const [collection, setCollection] = useState<ICollectionModel>();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const editCollection = useCallback(
    async (data: TEditCollectionViewModel) => {
      try {
        setLoading(true);

        await editCollectionUseCase(
          {
            editCollection: repository.editCollection,
          },
          data
        );
        setLoading(false);
        showNotification("Coleção alterada com sucesso", "SUCCESS");
        navigate(-1);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.editCollection, navigate]
  );

  const getCollection = useCallback(
    async (collectionId: number) => {
      try {
        setLoading(true);

        const data = await getCollectionUseCase(
          {
            getCollection: repository.getCollection,
          },
          collectionId
        );
        setCollection(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.getCollection]
  );

  return { isLoading, editCollection, getCollection, collection };
};

export { useEditCollectionViewModel };
