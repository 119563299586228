import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { useLessonRepository } from "@app/features/Lesson/data/lessonRepository";
import {
  useCreateLessonViewModel,
  TCreateLessonViewModel,
} from "@app/features/Lesson/view/Create/createLessonViewModel";
import lessonService from "@app/services/lesson";

import StepperTamplete from "@app/components/templates/Stepper";
import LoadingLock from "@app/components/molecules/LoadingLock";
import FooterActions from "@app/components/molecules/FooterActions";

import LessonForm, { TLessonForm } from "@app/features/Lesson/view/Form";
import QuestionForm, { TQuestionForm } from "@app/features/Question/view/Form";

const CreateLesson = () => {
  const { id } = useParams();

  const [currentStep, setCurrentStep] = useState(0);
  const [lessonData, setLessonData] = useState<TCreateLessonViewModel>();

  const lessonRepository = useLessonRepository(lessonService);
  const { createLesson, isLoading } =
    useCreateLessonViewModel(lessonRepository);

  const handleNextStep = useCallback(
    (data: TLessonForm) => {
      setCurrentStep((currentValue) => currentValue + 1);

      setLessonData({
        ...data,
        collectionId: Number(id),
      });
    },
    [id]
  );

  const handleSave = useCallback(
    (data: TQuestionForm) => {
      if (lessonData) {
        createLesson({ ...lessonData, questions: data.questions });
      }
    },
    [createLesson, lessonData]
  );

  const handleBackStep = useCallback(() => {
    setCurrentStep((currentValue) => currentValue - 1);
  }, []);

  return (
    <>
      <LoadingLock isLoading={isLoading} />
      <StepperTamplete
        currentStep={currentStep}
        stepsLabels={["Cadastro de aula", "Cadastro de perguntas"]}
        stepsScenes={[
          <LessonForm
            onValidateSuccess={handleNextStep}
            footerActions={<FooterActions saveLabel="Próximo" />}
          />,
          <QuestionForm
            onValidateSuccess={handleSave}
            footerActions={
              <FooterActions cancelLabel="Voltar" onCancel={handleBackStep} />
            }
          />,
        ]}
      />
    </>
  );
};

export default CreateLesson;
