import { ITagRepository } from "@app/features/Tag/data/tagRepository";
import { TEditTagViewModel } from "@app/features/Tag/views/Edit/editTagViewModel";

const editModuleUseCase = async (
  repository: Pick<ITagRepository, "editTag">,
  data: TEditTagViewModel
) => {
  await repository.editTag(data);
};

export default editModuleUseCase;
