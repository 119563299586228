import { useCallback } from "react";

import ITagEntity from "@app/features/Tag/data/ITagEntity";

import { TCreateTagRemoteData } from "@app/services/tag/remote-types/TCreateTagRemoteData";
import { TEditTagRemoteData } from "@app/services/tag/remote-types/TEditTagRemoteData";

import { ITagService, TListTagsSearchParams } from "@app/services/tag";
import IPaginationModel, { IPageModel } from "@app/constants/interfaces";

export interface ITagRepository {
  createTag(data: TCreateTagRemoteData): Promise<void>;
  editTag(data: TEditTagRemoteData): Promise<void>;
  deleteTag(tagId: number): Promise<void>;
  getTag(tagId: number): Promise<ITagEntity>;
  listTags(
    tagId: number,
    pageConfig?: IPageModel,
    filterParams?: TListTagsSearchParams
  ): Promise<IPaginationModel<ITagEntity>>;
}

const useTagRepository = (tagService: ITagService): ITagRepository => {
  const createTag = useCallback(
    async (data: TCreateTagRemoteData): Promise<void> => {
      await tagService.createTagService(data);
    },
    [tagService]
  );

  const editTag = useCallback(
    async (data: TEditTagRemoteData): Promise<void> => {
      await tagService.editTagService(data);
    },
    [tagService]
  );

  const listTags = useCallback(
    async (
      companyId: number,
      pageConfig?: IPageModel,
      filterParams?: TListTagsSearchParams
    ): Promise<IPaginationModel<ITagEntity>> => {
      const tags = await tagService.listTagsService(
        companyId,
        pageConfig,
        filterParams
      );
      return tags;
    },
    [tagService]
  );

  const getTag = useCallback(
    async (tagId: number): Promise<ITagEntity> => {
      const tag = await tagService.getTagService(tagId);
      return tag;
    },
    [tagService]
  );

  const deleteTag = useCallback(
    async (tagId: number): Promise<void> => {
      await tagService.deleteTagService(tagId);
    },
    [tagService]
  );

  return {
    createTag,
    editTag,
    listTags,
    deleteTag,
    getTag,
  };
};

export { useTagRepository };
