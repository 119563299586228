import { LanguageActionTypes, ILanguageStore } from "./languageActionsType";

const INITIAL_STATE: ILanguageStore = {
  loading: true,
  languages: [],
  currentLanguage: undefined,
};

const languageReducer = (
  state = INITIAL_STATE,
  action: LanguageActionTypes
): ILanguageStore => {
  switch (action.type) {
    case "LOADING_LANGUAGE":
      return { ...state, loading: action.payload };

    case "LANGUAGES":
      return { ...state, languages: action.payload, loading: false };

    case "SET_CURRENT_LANGUAGE":
      return { ...state, currentLanguage: action.payload, loading: false };

    default:
      return state;
  }
};

export default languageReducer;
