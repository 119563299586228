import { ICollectionRepository } from "@app/features/Collection/data/collectionRepository";

import ICollectionModel from "@app/features/Collection/domain/models/ICollectionModel";
import { IPageModel } from "@app/constants/interfaces";
import {
  setCollectionsAction,
  setLoadingCollectionsAction,
} from "@app/features/Collection/data/collectionActions";
import ICollectionEntity from "@app/features/Collection/data/ICollectionEntity";
import { TListTagsSearchParams } from "@app/services/tag";
import { dispatchStore } from "@app/config/store";

const listCollectionsUseCase = async (
  repository: Pick<ICollectionRepository, "listCollections">,
  companyId: number,
  pageConfig?: IPageModel,
  filterParams?: TListTagsSearchParams
): Promise<void> => {
  dispatchStore(setLoadingCollectionsAction(true));
  const collectionsEntity = await repository.listCollections(
    companyId,
    pageConfig,
    filterParams
  );
  const domain = mapToDomain(collectionsEntity);

  dispatchStore(setCollectionsAction(domain));
};

const mapToDomain = (tagEntity: ICollectionEntity[]): ICollectionModel[] => {
  const domain = tagEntity.map((tag): ICollectionModel => {
    const { id, name, language, companyId } = tag;

    return {
      id,
      name,
      companyId,
      language,
    };
  });

  return domain;
};

export default listCollectionsUseCase;
