import React, { useCallback, useEffect, useRef, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Chip, Stack } from "@mui/material";
import { FiPlus } from "react-icons/fi";
import { FilterAlt } from "@mui/icons-material";

import { useCollectionRepository } from "@app/features/Collection/data/collectionRepository";
import { useLessonRepository } from "@app/features/Lesson/data/lessonRepository";

import { useListLessonsViewModel } from "@app/features/Collection/views/List/ListLessons/listLessonViewModel";
import { useEditLessonViewModel } from "@app/features/Lesson/view/Edit/editLessonViewModel";

import ILessonModel from "@app/features/Lesson/domain/models/ILessonModel";
import { IOrderModel, IPageModel } from "@app/constants/interfaces";

import lessonService from "@app/services/lesson";
import collectionService, {
  TListPagesFromCollectionSearchParams,
} from "@app/services/collection";

import LoadingLock from "@app/components/molecules/LoadingLock";
import DrawerFilter, {
  IDrawerFilterRef,
  TFilterPayload,
} from "@app/components/organisms/DrawerFilter";
import StickyHeaderTable from "@app/components/organisms/StickyHeaderTable";
import StandardTemplate from "@app/components/templates/Standard";

import useRestricted from "@app/hooks/useRestricted";

import { ROLE } from "@app/constants/enums";
import { ROWS_PER_PAGE_OPTIONS } from "@app/constants/optionsSelect";
import { RootState } from "@app/config/store";

const ListLessons = () => {
  const { id: collectionId } = useParams();
  const { state } = useLocation();

  const { isAllowedTo } = useRestricted();
  const [paginationConfig, setPaginationConfig] = useState<IPageModel>({
    page: 0,
    amount: ROWS_PER_PAGE_OPTIONS.MIN,
  });
  const [filters, setFilters] = useState<TFilterPayload[]>([]);

  const { currentCompany } = useSelector((state: RootState) => state.company);

  const navigate = useNavigate();

  const collectionRepository = useCollectionRepository(collectionService);
  const lessonRepository = useLessonRepository(lessonService);

  const { isLoading, lessons, listLessons, removeLesson } =
    useListLessonsViewModel(collectionRepository, lessonRepository);
  const { editLesson } = useEditLessonViewModel(lessonRepository);

  const draweFilterRef = useRef<IDrawerFilterRef>();

  const handleCreateButton = useCallback(() => {
    navigate(`/admin/collections/${collectionId}/lessons/create`);
  }, [navigate, collectionId]);

  const handleSetFilter = (values: TFilterPayload[]) => {
    setFilters([...values]);
  };

  const handleGetNewPage = useCallback(
    (newPage: number, amount: number) => {
      setPaginationConfig({ amount, page: newPage });
      if (currentCompany) {
        listLessons(currentCompany.id, paginationConfig);
      }
    },
    [currentCompany, listLessons, paginationConfig]
  );

  const handleChangeOrderLesson = useCallback(
    (_: ILessonModel[], item: ILessonModel, orderEvent: IOrderModel) => {
      editLesson({ id: item.id, reoder: orderEvent }, false);
    },
    [editLesson]
  );

  const handleEdit = useCallback(
    (data: ILessonModel) => {
      navigate(`/admin/lessons/edit/${data.id}`);
    },
    [navigate]
  );

  const removeFilter = useCallback(
    (filter: TFilterPayload) => {
      let newFilters = filters.filter((f) => f.id !== filter.id);
      setFilters([...newFilters]);
    },
    [filters]
  );

  const handleDelete = useCallback(
    (data: ILessonModel) => {
      if (currentCompany) {
        removeLesson(data.id, currentCompany.id, paginationConfig);
      }
    },
    [currentCompany, paginationConfig, removeLesson]
  );

  useEffect(() => {
    let filtersData: TListPagesFromCollectionSearchParams = {};
    filters.forEach((f) => {
      if (f.value) {
        filtersData = { [f.id]: f.value };
      }
    });

    listLessons(Number(collectionId), paginationConfig, filtersData);
  }, [listLessons, collectionId, filters, paginationConfig]);

  return (
    <>
      <LoadingLock isLoading={isLoading} />

      <StandardTemplate
        title={`Coleção: ${state.collection.name}`}
        showBackButton
        secondaryButton={{
          isVisible: true,
          textButton: "Filtro",
          iconButton: <FilterAlt />,
          onClickButton: draweFilterRef.current?.openDrawer,
        }}
        primaryButton={{
          iconButton: <FiPlus />,
          textButton: "Novo",
          isVisible: isAllowedTo([ROLE.OWNER, ROLE.ADMIN]),
          onClickButton: handleCreateButton,
        }}
      >
        {!!filters.length && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ mb: 3 }}
          >
            {filters.map((filter) => (
              <Chip
                variant="outlined"
                label={`${filter.labelFilter}: ${filter.selectValueLabel}`}
                onDelete={() => removeFilter(filter)}
              />
            ))}
          </Stack>
        )}
        <StickyHeaderTable
          identifierColumnKey="id"
          pagination
          dialogMessage={{
            title: "Remover aula?",
            description:
              "Tem certeza que deseja remover esta aula? Ela será removida de todas as turmas que a possuem.",
          }}
          count={lessons.count}
          allowReordering
          onPageChange={handleGetNewPage}
          onOrderChange={handleChangeOrderLesson}
          onEdit={handleEdit}
          onDelete={handleDelete}
          columns={[
            {
              id: "image",
              label: "Capa",
              align: "left",
              component: "AVATAR",
            },
            {
              id: "name",
              label: "Nome",
              align: "left",
            },
            {
              id: "tag.name",
              label: "Marcador",
              align: "center",
              component: "CUSTOM",
              customComponent: (data) => {
                if (data.tag) {
                  return (
                    <Chip
                      label={data?.tag?.name}
                      sx={{ backgroundColor: data?.tag?.color }}
                    />
                  );
                }
              },
            },
          ]}
          rows={lessons.data}
        />
      </StandardTemplate>
      <DrawerFilter
        ref={draweFilterRef}
        onFilter={handleSetFilter}
        fields={[
          {
            id: "name",
            label: "Nome",
            type: "TEXT",
          },
        ]}
      />
    </>
  );
};

export default ListLessons;
