import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Chip, Stack } from "@mui/material";
import { FiPlus } from "react-icons/fi";
import { FilterAlt } from "@mui/icons-material";

import { useTagRepository } from "@app/features/Tag/data/tagRepository";
import { useListTagsViewModel } from "@app/features/Tag/views/List/listTagsViewModel";
import tagService, { TListTagsSearchParams } from "@app/services/tag";
import ITagModel from "@app/features/Tag/domain/models/ITagModel";

import LoadingLock from "@app/components/molecules/LoadingLock";
import DrawerFilter, {
  IDrawerFilterRef,
  TFilterPayload,
} from "@app/components/organisms/DrawerFilter";
import StickyHeaderTable from "@app/components/organisms/StickyHeaderTable";
import StandardTemplate from "@app/components/templates/Standard";

import useRestricted from "@app/hooks/useRestricted";

import { ROLE } from "@app/constants/enums";
import { IPageModel } from "@app/constants/interfaces";
import { ROWS_PER_PAGE_OPTIONS } from "@app/constants/optionsSelect";
import { RootState } from "@app/config/store";

const ListTags = () => {
  const { isAllowedTo } = useRestricted();
  const [paginationConfig, setPaginationConfig] = useState<IPageModel>({
    page: 0,
    amount: ROWS_PER_PAGE_OPTIONS.MIN,
  });
  const [filters, setFilters] = useState<TFilterPayload[]>([]);

  const { currentCompany } = useSelector((state: RootState) => state.company);
  const { languages } = useSelector((state: RootState) => state.language);

  const navigate = useNavigate();

  const tagRepository = useTagRepository(tagService);
  const { isLoading, tags, listTags, removeTag } =
    useListTagsViewModel(tagRepository);

  const draweFilterRef = useRef<IDrawerFilterRef>();

  const handleCreateButton = useCallback(() => {
    navigate("/admin/tags/create");
  }, [navigate]);

  const handleSetFilter = (values: TFilterPayload[]) => {
    setFilters([...values]);
  };

  const handleGetNewPage = (newPage: number, amount: number) => {
    setPaginationConfig({ amount, page: newPage });
    if (currentCompany) {
      listTags(currentCompany.id, paginationConfig);
    }
  };

  const handleEdit = useCallback(
    (data: ITagModel) => {
      navigate(`/admin/tags/edit/${data.id}`);
    },
    [navigate]
  );

  const removeFilter = (filter: TFilterPayload) => {
    let newFilters = filters.filter((f) => f.id !== filter.id);
    setFilters([...newFilters]);
  };

  const handleDelete = useCallback(
    (data: ITagModel) => {
      if (currentCompany) {
        removeTag(data.id, currentCompany.id, paginationConfig);
      }
    },
    [currentCompany, paginationConfig, removeTag]
  );

  const languagesData = useMemo(() => {
    return languages.map((item): { label: string; value: string } => {
      return {
        label: item.name,
        value: item.id.toString(),
      };
    });
  }, [languages]);

  useEffect(() => {
    let filtersData: TListTagsSearchParams = {};
    filters.forEach((f) => {
      if (f.value) {
        filtersData = { [f.id]: f.value };
      }
    });
    if (currentCompany) {
      listTags(currentCompany.id, paginationConfig, filtersData);
    }
  }, [listTags, currentCompany, filters, paginationConfig]);

  return (
    <>
      <LoadingLock isLoading={isLoading} />

      <StandardTemplate
        title="Marcadores"
        secondaryButton={{
          isVisible: true,
          textButton: "Filtro",
          iconButton: <FilterAlt />,
          onClickButton: draweFilterRef.current?.openDrawer,
        }}
        primaryButton={{
          iconButton: <FiPlus />,
          textButton: "Novo",
          isVisible: isAllowedTo([ROLE.OWNER, ROLE.ADMIN]),
          onClickButton: handleCreateButton,
        }}
      >
        {!!filters.length && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ mb: 3 }}
          >
            {filters.map((filter) => (
              <Chip
                variant="outlined"
                label={`${filter.labelFilter}: ${filter.selectValueLabel}`}
                onDelete={() => removeFilter(filter)}
              />
            ))}
          </Stack>
        )}
        <StickyHeaderTable
          identifierColumnKey="id"
          pagination
          dialogMessage={{
            title: "Remover marcador?",
            description: "Tem certeza que deseja remover este marcador?",
          }}
          count={tags.count}
          onPageChange={handleGetNewPage}
          onEdit={handleEdit}
          onDelete={handleDelete}
          columns={[
            {
              id: "color",
              label: "Cor",
              align: "left",
              component: "CUSTOM",
              customComponent: (data) => (
                <Box
                  sx={{
                    height: 25,
                    width: 25,
                    backgroundColor: `${data.color}`,
                    borderRadius: 20,
                  }}
                />
              ),
            },
            {
              id: "name",
              label: "Nome",
              align: "left",
            },
            {
              id: "language.name",
              label: "Idioma",
              align: "left",
            },
          ]}
          rows={tags.data}
        />
      </StandardTemplate>
      <DrawerFilter
        ref={draweFilterRef}
        onFilter={handleSetFilter}
        fields={[
          {
            id: "name",
            label: "Nome",
            type: "TEXT",
          },
          {
            id: "language",
            label: "Idioma do recurso",
            type: "SELECT",
            options: languagesData,
          },
        ]}
      />
    </>
  );
};

export default ListTags;
