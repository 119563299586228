import { ICollectionRepository } from "@app/features/Collection/data/collectionRepository";

const deleteCollectionUseCase = async (
  repository: Pick<ICollectionRepository, "deleteCollection">,
  collectionId: number
) => {
  await repository.deleteCollection(collectionId);
};

export default deleteCollectionUseCase;
