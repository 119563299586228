import { ITagRepository } from "@app/features/Tag/data/tagRepository";

import ITagModel from "@app/features/Tag/domain/models/ITagModel";

import ITagEntity from "@app/features/Tag/data/ITagEntity";

const getTagUseCase = async (
  repository: Pick<ITagRepository, "getTag">,
  tagId: number
): Promise<ITagModel> => {
  const tagEntity = await repository.getTag(tagId);
  const domain = mapToDomain(tagEntity);

  return domain;
};

const mapToDomain = (tagEntity: ITagEntity): ITagModel => {
  const { id, name, color, language } = tagEntity;

  return {
    id,
    name,
    color,
    language,
  };
};

export default getTagUseCase;
