import React, {
  forwardRef,
  RefObject,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { Box, Button, Chip, Grid, Toolbar, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { useCollectionRepository } from "@app/features/Collection/data/collectionRepository";
import { useLessonRepository } from "@app/features/Lesson/data/lessonRepository";

import ICollectionModel from "@app/features/Collection/domain/models/ICollectionModel";
import ILessonModel from "@app/features/Lesson/domain/models/ILessonModel";

import { useListLessonsViewModel } from "@app/features/Collection/views/List/ListLessons/listLessonViewModel";
import LessonSettingsForm, {
  ITableSettingsFormRef,
  TLessonSettingsForm,
} from "@app/features/Module/view/Form/LessonSettingsForm";

import collectionService from "@app/services/collection";
import lessonService from "@app/services/lesson";

import TabPanel from "@app/components/templates/TabPanel";
import StandardTemplate from "@app/components/templates/Standard";
import Drawer, { IDrawerRef } from "@app/components/molecules/Drawer";
import LoadingLock from "@app/components/molecules/LoadingLock";

import CardCollection from "@app/components/organisms/CardCollection";
import StickyHeaderTable from "@app/components/organisms/StickyHeaderTable";

import { RootState } from "@app/config/store";
import theme from "@app/config/theme";

export type DrawerSelectLessonsRef = {
  openDrawer: (moduleId: number) => void;
};

type DrawerSelectLessonsProps = {
  ref?: RefObject<DrawerSelectLessonsRef>;
  onSelectLessons: (
    data: ILessonModel[],
    dataForm: TLessonSettingsForm,
    moduleId: number
  ) => void;
};

enum TABS {
  LIST_COLLECTION = 0,
  SELECT_LESSON = 1,
  LESSON_SETTINGS_FORM = 2,
}

const DrawerSelectLessons = forwardRef<unknown, DrawerSelectLessonsProps>(
  ({ onSelectLessons }: DrawerSelectLessonsProps, ref) => {
    const drawerRef = useRef<IDrawerRef>();
    const tableSettingsFormRef = useRef<ITableSettingsFormRef>();

    const [currentTab, setCurrentTab] = useState<TABS>(TABS.LIST_COLLECTION);
    const [selectedLessons, setSelectedLessons] = useState<ILessonModel[]>([]);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [moduleId, setModuleId] = useState<number>();

    const collectionRepository = useCollectionRepository(collectionService);
    const lessonRepository = useLessonRepository(lessonService);

    const { lessons, listLessons } = useListLessonsViewModel(
      collectionRepository,
      lessonRepository
    );

    const { collections, loading } = useSelector(
      (state: RootState) => state.collection
    );

    const goToTab = useCallback((value: TABS) => {
      setCurrentTab(value);
    }, []);

    const handleCancel = useCallback(() => {
      drawerRef.current?.closeDrawer();
      setSelectedLessons([]);
      setSelectedIds([]);
      goToTab(TABS.LIST_COLLECTION);
    }, [goToTab]);

    const openDrawer = useCallback((moduleId: number) => {
      setModuleId(moduleId);
      drawerRef.current?.openDrawer();
    }, []);

    const handleSelectCollection = useCallback(
      (collection: ICollectionModel) => {
        setCurrentTab(1);
        listLessons(collection.id, { amount: 10, page: 0 });
      },
      [listLessons]
    );

    const backTab = useCallback(() => {
      setCurrentTab((value) => {
        if (value === TABS.SELECT_LESSON) {
          setSelectedLessons([]);
          setSelectedIds([]);
        }
        return value - 1;
      });
    }, []);

    const gotToSettingsTab = useCallback(() => {
      goToTab(2);
    }, [goToTab]);

    const handleSelectLesson = useCallback(
      (ids: number[], selectedRows: ILessonModel[]) => {
        setSelectedLessons(selectedRows);
        setSelectedIds(ids);
      },
      []
    );

    const handleValidateSettings = useCallback(async () => {
      const data = await tableSettingsFormRef.current?.validate();
      if (data && moduleId) {
        onSelectLessons(selectedLessons, data, moduleId);
        drawerRef.current?.closeDrawer();
        setSelectedLessons([]);
        setSelectedIds([]);
        goToTab(TABS.LIST_COLLECTION);
      }
    }, [selectedLessons, onSelectLessons, moduleId, goToTab]);

    const title = useMemo(() => {
      switch (currentTab) {
        case 0:
          return "Selecione uma coleção";
        case 1:
          return "Selecione as aulas";
        case 2:
          return "Configurações";
        default:
          return "Selecione uma coleção";
      }
    }, [currentTab]);

    useImperativeHandle(ref, () => ({ openDrawer }), [openDrawer]);

    return (
      <Drawer ref={drawerRef} drawerPosition="bottom" onClose={handleCancel}>
        <Box
          sx={{
            position: "sticky",
            overflow: "hidden",
            top: 0,
            zIndex: 9999,
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Typography
              variant="h6"
              id="tableTitle"
              color="white"
              component="div"
            >
              {`${selectedLessons.length} aula(s) selecionada(s)`}
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="inherit"
                size="small"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                disabled={
                  selectedLessons.length === 0 ||
                  currentTab === TABS.LESSON_SETTINGS_FORM
                }
                sx={{ ml: 2 }}
                onClick={gotToSettingsTab}
              >
                Próximo
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                disabled={currentTab !== 2}
                sx={{ ml: 2 }}
                onClick={handleValidateSettings}
              >
                Adicionar aula(s)
              </Button>
            </Box>
          </Toolbar>
        </Box>
        <Box
          sx={{ backgroundColor: "#FFF", height: "75vh", p: 3 }}
          role="presentation"
        >
          <StandardTemplate
            title={title}
            showBackButton={currentTab !== 0}
            onBackButton={backTab}
          >
            <TabPanel value={currentTab} index={0}>
              {loading && <LoadingLock isLoading={loading} />}
              <Grid container spacing={4}>
                {collections.map((collection) => (
                  <Grid key={collection.id} item xs={12} sm={3}>
                    <CardCollection
                      data={collection}
                      onSelect={handleSelectCollection}
                    />
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <StickyHeaderTable
                identifierColumnKey="id"
                allowSelection
                onSelectionChange={handleSelectLesson}
                itemsSelected={selectedIds}
                count={lessons.count}
                columns={[
                  {
                    id: "image",
                    label: "Capa",
                    align: "left",
                    component: "AVATAR",
                  },
                  {
                    id: "name",
                    label: "Nome",
                    align: "left",
                  },
                  {
                    id: "tag.name",
                    label: "Marcador",
                    align: "center",
                    component: "CUSTOM",
                    customComponent: (data) => {
                      if (data.tag) {
                        return (
                          <Chip
                            label={data?.tag?.name}
                            sx={{ backgroundColor: data?.tag?.color }}
                          />
                        );
                      }
                    },
                  },
                ]}
                rows={lessons.data}
              />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <LessonSettingsForm ref={tableSettingsFormRef} edit={false} />
            </TabPanel>
          </StandardTemplate>
        </Box>
      </Drawer>
    );
  }
);

export default DrawerSelectLessons;
