import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { useTagRepository } from "@app/features/Tag/data/tagRepository";
import { useEditTagViewModel } from "@app/features/Tag/views/Edit/editTagViewModel";
import tagService from "@app/services/tag";

import StandardTemplate from "@app/components/templates/Standard";
import LoadingLock from "@app/components/molecules/LoadingLock";
import FooterActions from "@app/components/molecules/FooterActions";

import TagForm, { TTagForm } from "@app/features/Tag/views/Form";

import { RootState } from "@app/config/store";
import { useParams } from "react-router-dom";

const EditTag = () => {
  const { id } = useParams();
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const tagRepository = useTagRepository(tagService);
  const { editTag, isLoading, getTag, tag } =
    useEditTagViewModel(tagRepository);

  const handleSave = useCallback(
    (data: TTagForm) => {
      if (currentCompany) {
        editTag({ id: Number(id), companyId: currentCompany.id, ...data });
      }
    },
    [editTag, currentCompany, id]
  );

  useEffect(() => {
    if (id) {
      getTag(Number(id));
    }
  }, [id, getTag]);

  const tagData = useMemo(() => {
    if (tag) {
      return { ...tag, languageId: tag.language.id };
    }
  }, [tag]);

  return (
    <StandardTemplate title="Editar marcador" showBackButton>
      <LoadingLock isLoading={isLoading} />
      <TagForm
        dataForm={tagData}
        onValidateSuccess={handleSave}
        footerActions={<FooterActions />}
      />
    </StandardTemplate>
  );
};

export default EditTag;
