import { useCallback, useState } from "react";

import { ITagRepository } from "@app/features/Tag/data/tagRepository";
import listTagsUseCases from "@app/features/Tag/domain/use-cases/listTagsUseCase";
import deleteTagUseCase from "@app/features/Tag/domain/use-cases/deleteTagUseCase";

import ITagModel from "@app/features/Tag/domain/models/ITagModel";
import IPaginationModel, { IPageModel } from "@app/constants/interfaces";

import handleApplicationError from "@app/handlers/handleApiError";
import { TListTagsSearchParams } from "@app/services/tag";
import showNotification from "@app/components/molecules/Toast";

const useListTagsViewModel = (repository: ITagRepository) => {
  const [isLoading, setLoading] = useState(false);
  const [tags, setTags] = useState<IPaginationModel<ITagModel>>({
    count: 0,
    data: [],
  });

  const listTags = useCallback(
    async (
      companyId: number,
      pageConfig?: IPageModel,
      filterParams?: TListTagsSearchParams
    ) => {
      try {
        setLoading(true);

        const result = await listTagsUseCases(
          {
            listTags: repository.listTags,
          },
          companyId,
          pageConfig,
          filterParams
        );
        setTags(result as IPaginationModel<ITagModel>);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.listTags]
  );

  const removeTag = useCallback(
    async (tagId: number, companyId: number, pageConfig: IPageModel) => {
      try {
        setLoading(true);

        await deleteTagUseCase(
          {
            deleteTag: repository.deleteTag,
          },
          tagId
        );
        setLoading(false);
        listTags(companyId, pageConfig);
        showNotification("Marcador removido com sucesso", "SUCCESS");
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.deleteTag, listTags]
  );

  return { tags, isLoading, listTags, removeTag };
};

export { useListTagsViewModel };
