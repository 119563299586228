import { ICollectionRepository } from "@app/features/Collection/data/collectionRepository";
import { TCreateCollectionViewModel } from "@app/features/Collection/views/Create/createCollectionViewModel";

const createCollectionUseCase = async (
  repository: Pick<ICollectionRepository, "createCollection">,
  data: TCreateCollectionViewModel
) => {
  await repository.createCollection(data);
};

export default createCollectionUseCase;
