import React, { useCallback, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Chip, Stack } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";

import { useClasseRepository } from "@app/features/Classe/data/classeRespository";
import { useListStudentsViewModel } from "@app/features/Student/view/List/listStudentViewModel";
import classeService, {
  TListUsersFromClasseSearchParams,
} from "@app/services/classe";

import LoadingLock from "@app/components/molecules/LoadingLock";
import DrawerFilter, {
  IDrawerFilterRef,
  TFilterPayload,
} from "@app/components/organisms/DrawerFilter";
import StickyHeaderTable from "@app/components/organisms/StickyHeaderTable";

import StandardTemplate from "@app/components/templates/Standard";
import { RootState } from "@app/config/store";
import IUserProfileModel from "@app/features/User/domain/models/IUserModel";
import { USER_CLASSE_STATUS } from "@app/constants/enums";
import { IPageModel } from "@app/constants/interfaces";
import { ROWS_PER_PAGE_OPTIONS } from "@app/constants/optionsSelect";
import { FiPlus } from "react-icons/fi";

const ListStudents = () => {
  const [paginationConfig, setPaginationConfig] = useState<IPageModel>({
    page: 0,
    amount: ROWS_PER_PAGE_OPTIONS.MIN,
  });
  const [filters, setFilters] = useState<TFilterPayload[]>([]);

  const { currentClasse } = useSelector((state: RootState) => state.classe);

  const navigate = useNavigate();

  const classeRepository = useClasseRepository(classeService);
  const {
    isLoading,
    students,
    listStudentsFromClasse,
    removeStudentsFromClasse,
  } = useListStudentsViewModel(classeRepository);

  const draweFilterRef = useRef<IDrawerFilterRef>();

  const handleCreateButton = () => {
    navigate("/admin/students/create");
  };

  const handleSetFilter = (values: TFilterPayload[]) => {
    setFilters([...values]);
  };

  const handleGetNewPage = (newPage: number, amount: number) => {
    setPaginationConfig({ amount, page: newPage });
    if (currentClasse) {
      listStudentsFromClasse(currentClasse.id, paginationConfig);
    }
  };

  const handleEdit = useCallback(
    (data: IUserProfileModel) => {
      navigate(`/admin/students/view/${data.id}`);
    },
    [navigate]
  );

  const removeFilter = (filter: TFilterPayload) => {
    let newFilters = filters.filter((f) => f.id !== filter.id);
    setFilters([...newFilters]);
  };

  const handleDelete = useCallback(
    (data: IUserProfileModel) => {
      if (currentClasse) {
        removeStudentsFromClasse(currentClasse.id, data.id, paginationConfig);
      }
    },
    [currentClasse, paginationConfig, removeStudentsFromClasse]
  );

  const getColorStatus = useCallback(
    (data: USER_CLASSE_STATUS): "info" | "success" | "warning" => {
      switch (data) {
        case USER_CLASSE_STATUS.ACTIVE:
          return "success";
        case USER_CLASSE_STATUS.INACTIVE:
          return "warning";
        default:
          return "info";
      }
    },
    []
  );

  useEffect(() => {
    let filtersData: TListUsersFromClasseSearchParams = {};
    filters.forEach((f) => {
      if (f.value) {
        filtersData = { [f.id]: f.value };
      }
    });
    if (currentClasse) {
      listStudentsFromClasse(currentClasse.id, paginationConfig, filtersData);
    }
  }, [listStudentsFromClasse, currentClasse, filters, paginationConfig]);

  return (
    <>
      <LoadingLock isLoading={isLoading} />

      <StandardTemplate
        title="Alunos"
        secondaryButton={{
          isVisible: true,
          onClickButton: draweFilterRef.current?.openDrawer,
          iconButton: <FilterAlt />,
          textButton: "Filtro",
        }}
        primaryButton={{
          isVisible: true,
          textButton: "Novo",
          onClickButton: handleCreateButton,
          iconButton: <FiPlus />,
        }}
      >
        {!!filters.length && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ mb: 3 }}
          >
            {filters.map((filter) => (
              <Chip
                key={filter.id}
                variant="outlined"
                label={`${filter.labelFilter}: ${filter.selectValueLabel}`}
                onDelete={() => removeFilter(filter)}
              />
            ))}
          </Stack>
        )}
        <StickyHeaderTable
          identifierColumnKey="id"
          pagination
          dialogMessage={{
            title: "Remover aluno?",
            description:
              "Tem certeza que deseja remover este aluno? Ele perderá acesso a sua turma e não poderá mais acessar os conteúdos.",
          }}
          count={students.count}
          onPageChange={handleGetNewPage}
          onEdit={handleEdit}
          onDelete={handleDelete}
          columns={[
            {
              id: "photo",
              label: "Avatar",
              component: "AVATAR",
            },
            {
              id: "name",
              label: "Nome",
              align: "left",
            },
            {
              id: "email",
              label: "E-mail",
              align: "left",
            },
            {
              id: "expireAt",
              label: "Acesso expira",
              align: "center",
              format: (value) => {
                if (value) {
                  return new Date(value).toLocaleDateString("pt-br");
                }
                return "-";
              },
            },
            {
              id: "userStatusClasse",
              label: "Status da conta",
              align: "center",
              component: "CUSTOM",
              customComponent: (data) => (
                <Chip
                  label={data.userStatusClasse}
                  color={getColorStatus(data.userStatusClasse)}
                />
              ),
            },
          ]}
          rows={students.data}
        />
      </StandardTemplate>
      <DrawerFilter
        ref={draweFilterRef}
        onFilter={handleSetFilter}
        fields={[
          {
            id: "name",
            label: "Nome",
            type: "TEXT",
          },
          {
            id: "email",
            label: "Email",
            type: "TEXT",
          },
        ]}
      />
    </>
  );
};

export default ListStudents;
