import { IModulePageRepository } from "@app/features/ModulePage/data/modulePageRepository";

import IModulePageModel from "@app/features/ModulePage/domain/models/IModulePageModel";
import IModulePageEntity from "@app/features/ModulePage/data/IModulePageEntity";

const getLessonDetailFromModuleUseCase = async (
  repository: Pick<IModulePageRepository, "getLessonDetailFromModule">,
  moduleId: number,
  lessonId: number
): Promise<IModulePageModel> => {
  const entity = await repository.getLessonDetailFromModule(moduleId, lessonId);

  const dataDomain = mapToDomain(entity);

  return dataDomain;
};

const mapToDomain = (resource: IModulePageEntity): IModulePageModel => {
  const { releaseDateType, releaseAfterDay, releaseDate } = resource;

  return {
    releaseDateType,
    releaseAfterDay,
    releaseDate,
  };
};

export default getLessonDetailFromModuleUseCase;
