import {
  forwardRef,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";

import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { FiPlus } from "react-icons/fi";

import CreateTag from "@app/features/Tag/views/Create";

import { useResourceRepository } from "@app/features/Resource/data/resourceRepository";

import resourceService from "@app/services/resource";

import { useFormResourceViewModel } from "@app/features/Resource/views/Form/formResourcesViewModel";
import YoutubeResourcesForm, {
  YoutubeResourcesFormRef,
} from "@app/features/Resource/views/Form/YoutubeResourceForm";
import FileForm, {
  FileFormRef,
} from "@app/features/Resource/views/Form/FileForm";

import Drawer, { IDrawerRef } from "@app/components/molecules/Drawer";
import SelectUseForm from "@app/components/organisms/SelectUseForm";
import { TAcceptedFileType } from "@app/components/atoms/FileInput";
import ResourceType, {
  OptionType,
} from "@app/components/organisms/ResourceType";

import { RootState } from "@app/config/store";
import { useTagRepository } from "@app/features/Tag/data/tagRepository";
import tagService from "@app/services/tag";

export type TResourceForm = {
  name: string;
  url: string;
  fileKey?: string;
  size?: number;
  languageId: number;
  tagId?: number;
};

export type TResourceFormRef = {
  validate: () => Promise<TResourceForm[] | undefined>;
};

type ResourcesFormProps = {
  ref?: RefObject<TResourceFormRef>;
  allowSelectLanguage: boolean;
  onValidateSuccess?: (data: TResourceForm[]) => void;
  footerActions: ReactNode;
  predefinedResourceType?: OptionType;
  maxItems?: number;
  acceptedFiles?: TAcceptedFileType;
};

const ResourcesForm = forwardRef<unknown, ResourcesFormProps>(
  (
    {
      predefinedResourceType,
      allowSelectLanguage,
      footerActions,
      onValidateSuccess,
      maxItems,
      acceptedFiles,
    }: ResourcesFormProps,
    ref
  ) => {
    const youtubeResourcesFormRef = useRef<YoutubeResourcesFormRef>();
    const fileFormRef = useRef<FileFormRef>();

    const drawerTagRef = useRef<IDrawerRef>();

    const { currentClasse } = useSelector((state: RootState) => state.classe);
    const { currentCompany } = useSelector((state: RootState) => state.company);
    const { languages } = useSelector((state: RootState) => state.language);

    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      setValue,
      watch,
      trigger,
      getValues,
    } = useForm({
      resolver: yupResolver(
        yup.object().shape({
          languageId: yup
            .number()
            .typeError("Selecione o idioma do recurso")
            .required("Selecione o idioma dos recursos"),
          tagId: yup.number().optional(),
          resourceType: yup
            .mixed()
            .oneOf([OptionType.EMBED, OptionType.FILE])
            .required(),
        })
      ),
      defaultValues: {
        languageId: currentClasse?.languageId ?? NaN,
        tagId: undefined,
        resourceType: OptionType.FILE,
      },
      mode: "onSubmit",
    });
    const [languageId, tagId, resourceType] = watch([
      "languageId",
      "tagId",
      "resourceType",
    ]);

    const resourceRepository = useResourceRepository(resourceService);
    const tagRepository = useTagRepository(tagService);

    const { getTags, tags } = useFormResourceViewModel(
      tagRepository,
      resourceRepository
    );

    const handleClearLanguage = useCallback(() => {
      setValue("languageId", NaN);
    }, [setValue]);

    const handleChangeResourceType = useCallback(
      (value: OptionType) => {
        setValue("resourceType", value);
      },
      [setValue]
    );

    const onSubmit = useCallback(async () => {
      let resourcesData: TResourceForm[] = [];
      switch (resourceType) {
        case OptionType.EMBED:
          const youtubeFormValue =
            await youtubeResourcesFormRef.current?.validate();

          resourcesData =
            youtubeFormValue?.resources.map((item): TResourceForm => {
              const { name, url } = item;

              return {
                languageId,
                tagId,
                name,
                url,
              };
            }) || [];

          break;

        default:
          const fileFormValue = await fileFormRef.current?.validate();

          resourcesData =
            fileFormValue?.resources.map((item): TResourceForm => {
              const { name, url, fileKey, size } = item;

              return {
                fileKey,
                size,
                languageId,
                tagId,
                name,
                url,
              };
            }) || [];
          break;
      }
      if (onValidateSuccess && !!resourcesData.length) {
        onValidateSuccess(resourcesData);
      }

      return resourcesData;
    }, [resourceType, languageId, tagId, onValidateSuccess]);

    const validate = useCallback(async (): Promise<
      TResourceForm[] | undefined
    > => {
      const value = await trigger();
      if (value) {
        const formData = getValues();
        const resources = await onSubmit();
        resources.map((r) => ({
          ...r,
          languageId: formData.languageId,
          tagId: formData.tagId,
        }));
        return resources;
      }
    }, [onSubmit, getValues, trigger]);

    const handleCloseDrawerTag = useCallback(() => {
      drawerTagRef.current?.closeDrawer();
      if (currentCompany) {
        getTags(currentCompany.id, languageId);
      }
    }, [getTags, currentCompany, languageId]);

    const handleOpenTagDrawer = useCallback(() => {
      drawerTagRef.current?.openDrawer();
    }, []);

    useImperativeHandle(ref, () => ({ validate }), [validate]);

    const languageData = useMemo(() => {
      const data = languages.find((l) => l.id === languageId);

      return data;
    }, [languageId, languages]);

    useEffect(() => {
      if (currentCompany && languageId) {
        getTags(currentCompany.id, languageId);
      }
    }, [getTags, currentCompany, languageId]);

    return (
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              sm={12}
              justifyContent="center"
              alignItems="center"
            >
              {!!languageId ? (
                <Stack direction="row" spacing={1} justifyContent="center">
                  {allowSelectLanguage ? (
                    <Chip
                      avatar={<Avatar src={languageData?.image} />}
                      label={languageData?.name}
                      onDelete={handleClearLanguage}
                    />
                  ) : (
                    <Chip
                      avatar={<Avatar src={languageData?.image} />}
                      label={languageData?.name}
                    />
                  )}
                </Stack>
              ) : (
                <SelectUseForm
                  error={!!errors.languageId}
                  helperText={errors.languageId?.message}
                  control={control}
                  label="Selecione o idioma para os materiais"
                  defaultValue={String(languageId)}
                  {...register("languageId")}
                >
                  <MenuItem value="">
                    Selecione o idioma para os materiais
                  </MenuItem>
                  {languages.map((language) => (
                    <MenuItem value={language.id} key={language.key}>
                      {language.name}
                    </MenuItem>
                  ))}
                </SelectUseForm>
              )}
            </Grid>
            {!!languageId && (
              <>
                <Grid item xs={12} sm={12}>
                  <Divider />
                </Grid>

                {!predefinedResourceType && (
                  <ResourceType
                    maxItems={maxItems}
                    onChange={handleChangeResourceType}
                    selected={resourceType}
                  />
                )}

                <Grid item xs={10} sm={11}>
                  <SelectUseForm
                    error={!!errors.tagId}
                    helperText={errors.tagId?.message}
                    control={control}
                    label="Marcador"
                    defaultValue={String(getValues("tagId"))}
                    {...register("tagId")}
                  >
                    {tags.map((option) => (
                      <MenuItem value={option.id} key={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </SelectUseForm>
                </Grid>
                <Grid item xs={2} sm={1} sx={{ mt: 2 }}>
                  <IconButton
                    aria-label="delete"
                    size="large"
                    disabled={false}
                    onClick={handleOpenTagDrawer}
                  >
                    <FiPlus />
                  </IconButton>
                </Grid>

                {resourceType === "FILE" && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FileForm
                      ref={fileFormRef}
                      maxItems={maxItems}
                      acceptedFiles={acceptedFiles}
                    />
                  </Grid>
                )}

                {resourceType === "EMBED" && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <YoutubeResourcesForm
                      ref={youtubeResourcesFormRef}
                      maxItems={maxItems}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Paper>
        <Box sx={{ mt: 10 }}>{footerActions}</Box>
        <Drawer ref={drawerTagRef} drawerPosition="bottom">
          <Box sx={{ p: 4 }}>
            <CreateTag onFinish={handleCloseDrawerTag} />
          </Box>
        </Drawer>
      </form>
    );
  }
);

export default ResourcesForm;
