import { IModuleRepository } from "@app/features/Module/data/moduleRepository";

import ILessonModel from "@app/features/Lesson/domain/models/ILessonModel";

import ILessonEntity from "@app/features/Lesson/data/ILessonEntity";

const getLessonsModulesUseCase = async (
  repository: Pick<IModuleRepository, "getLessonsFromModule">,
  moduleId: number
) => {
  const lessonsEntity = await repository.getLessonsFromModule(moduleId);
  const domain = mapToDomain(lessonsEntity);

  return domain;
};

const mapToDomain = (lessonsEntity: ILessonEntity[]): ILessonModel[] => {
  const domain = lessonsEntity.map((lesson): ILessonModel => {
    const {
      id,
      name,
      description,
      image,
      complementaryMaterials,
      resourceMedia,
      canRetake,
      minScore,
      minScoreValue,
      randomAnswers,
      randomQuestions,
      showResult,
    } = lesson;

    return {
      id,
      name,
      description,
      image,
      complementaryMaterials,
      resourceMedia,
      canRetake,
      minScore,
      minScoreValue,
      randomAnswers,
      randomQuestions,
      showResult,
    };
  });

  return domain;
};

export default getLessonsModulesUseCase;
