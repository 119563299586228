import React, { ReactNode, useCallback } from "react";
import { Control, Controller, Path } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";

type SelectUseFormProps<T extends Record<string, any>> = {
  name: string;
  label: string;
  control: Control<T>;
  defaultValue: string;
  children: ReactNode;
  error?: boolean;
  disabled?: boolean;
  helperText?: string;
};

const SelectUseForm = <T extends Record<string, any>>({
  name,
  label,
  control,
  defaultValue,
  children,
  error,
  disabled,
  helperText,
}: SelectUseFormProps<T>) => {
  const labelId = `${name}-label`;

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <FormControl margin="normal" variant="outlined" error={error} fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name as Path<T>}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <Select
              disabled={disabled}
              value={value}
              onClick={handleClick}
              defaultValue={defaultValue}
              onChange={onChange}
              label={label}
              labelId={labelId}
            >
              {children}
            </Select>
            {error && <FormHelperText>{helperText}</FormHelperText>}
          </>
        )}
      />
    </FormControl>
  );
};

SelectUseForm.defaultProps = {
  error: false,
  helperText: "",
};

export default SelectUseForm;
