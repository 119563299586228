import { ILessonRepository } from "@app/features/Lesson/data/lessonRepository";

const createModuleUseCase = async (
  repository: Pick<ILessonRepository, "removeLesson">,
  lessonId: number
) => {
  await repository.removeLesson(lessonId);
};

export default createModuleUseCase;
